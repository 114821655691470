import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Translate } from "react-localize-redux";

import { FormText, Input, Button } from 'reactstrap';
import Tag from '../../../components/Tag/Tag';
import s from '../Search.module.scss';


const SearchBar = (props) => {
    const {
        selectedTags = [],
        popularTags = [],
        onChanged
    } = props

    console.log('Search bar', selectedTags, props)

    const [searchDraft, setSearchDraft] = useState({
      tags: selectedTags,
      query: ""
    });

    useEffect(() => {
      onChanged(searchDraft)
    }, [searchDraft])

    function changeInput(event) {
      setSearchDraft({ ...searchDraft, query: event.target.value })
    }

    function toggleTagSelection(tag) {
      if (searchDraft.tags.find(t => t._id === tag._id) != null) {
        setSearchDraft({ ...searchDraft, tags: searchDraft.tags.filter(t => t._id !== tag._id) })
      } else {
        setSearchDraft({ ...searchDraft, tags: [...searchDraft.tags, tag] })
      }
    }

    return (
      <div className={`${s.searchBarRootContainer}`}>
        <div className='d-flex flex-row'>
          <div className={` ${s.searchTitle}`}><Translate id="search.search_title"/></div>
          <Link className={` ${s.closeButtonRight}`} to="/app/dashboard">
            <i className={`eva eva-close-outline ${s.evaBigSize}`}/>
            {/* <button className={`${s.close} ${s.searchBarCancelButton}`}> */}
              {/* <Translate id="search.cancel"/> */}
            {/* </button> */}
          </Link>
        </div>
        
        <div className={`${s.searchBarFrame}`}>
          <div className="d-flex">
            <Translate>
              {({translate}) =>
                <Input
                    id="searchBar"
                    className={`input-transparent pl-3 w-100 ${s.searchBarInput}`}
                    value={searchDraft.query}
                    onChange={(event) => changeInput(event)}
                    type="search"
                    required
                    name="Search"
                    autoComplete="off"
                    placeholder={translate('search.search_button')}
                />
              }
            </Translate>

          </div>
          <div className={`${s.tagsContainer}`}>
            <div className={`d-flex flex-row flex-wrap ${s.gap10}`}>
              {
                
                popularTags.map((tag, index) => (
                    <Tag key={index} tag={tag} title={tag.title}
                    isSelected={searchDraft.tags.find(t => t._id == tag._id) != null}
                    onClick={() => { toggleTagSelection(popularTags[index]) }} />
                ))
              }
            </div>
          </div>
          
        </div>
      </div>
    )
  };

  SearchBar.propTypes = {
    dispatch: PropTypes.func.isRequired,
    popularTags: PropTypes.array,
  }
  
  export default SearchBar;