import React from 'react';
import PropTypes from 'prop-types';

import s from './Tag.module.scss';
import classNames from 'classnames';
import { Translate } from 'react-localize-redux';

const Tag = (props) => {
  const {
    title = null,
    isSelected = false,
    className = '',
    headerClass = '',
    children = [],
    options = {},
    ...restProps
  } = props;

  return (
      <div className={`${s.tag} ${className} ${isSelected ? s.selected : ''}`} {...restProps}>
        {isSelected && <i className={`eva eva-checkmark-outline ${s.selectedIcon}`}/>}
        <Translate id={"records." + title} options={{ onMissingTranslation:({ translationId, languageCode }) => {
          return title
        } }} />
      </div>
  )
}

Tag.propTypes = {
  title: PropTypes.node,
  className: PropTypes.string,
  isSelected: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  options: PropTypes.object,
}

export default Tag;
