import {
  REGISTER_FAILURE,
  REGISTER_REQUEST,
  REGISTER_SUCCESS
} from "../actions/register.js";

const authenticated = localStorage.getItem('authenticated');

export default function register(state = {
  isFetching: false,
  errorMessage: '',
  isAuthenticated: authenticated,
}, action) {
  switch (action.type) {
    case REGISTER_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case REGISTER_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: '',
        isAuthenticated: true,
      });
    case REGISTER_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: action.payload,
        isAuthenticated: false,
      });
    default:
      return state;
  }
}