import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_SUCCESS,
  CLEAR_AUTH_ERRORS,
  RESET_PASSWORD_SUCCESS,
} from "../actions/auth.js";

import {
  REGISTER_SUCCESS,
} from "../actions/register.js";

const authenticated = JSON.parse(localStorage.getItem('authenticated')) ?? false;

export default function auth(state = {
  isFetching: false,
  isAuthenticated: authenticated,
  successfullyResetPassword: false,
}, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: true,
        errorMessage: '',
      });
    case LOGIN_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: false,
        errorMessage: action.payload,
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isAuthenticated: false,
      });
    case CLEAR_AUTH_ERRORS:
      return Object.assign({}, state, {
        errorMessage: '',
      });
    case REGISTER_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: true,
        errorMessage: '',
      });
      case RESET_PASSWORD_SUCCESS:
      return Object.assign({}, state, {
        successfullyResetPassword: true,
      });
      
    default:
      return state;
  }
}
