
import s from './LoadingIndicator.module.scss';
import React from 'react';

class LoadingIndicator extends React.Component {
  render() {
    return (
        <div className={`${s.loader}`}></div>
    );
  }
}

export default LoadingIndicator;
