import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Route, useHistory, withRouter, useLocation } from "react-router";
import { connect } from "react-redux";
import { Translate } from "react-localize-redux";
import Toggle from "./Toggle.js";

import {
  Navbar,
  Nav,
  NavItem,
  NavLink,
  InputGroupAddon,
  InputGroup,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Form,
  FormGroup,
  Modal,
  FormText,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
} from "reactstrap";

import { logoutUser } from "../../actions/auth";
import { createProject } from "../../actions/projects";
import { getNotifications } from "../../actions/notifications";

import { closeSidebar, openSidebar } from "../../actions/navigation";

import MenuIcon from "../Icons/HeaderIcons/MenuIcon";
import SearchBarIcon from "../Icons/HeaderIcons/SearchBarIcon";
import SearchIcon from "../Icons/HeaderIcons/SearchIcon";

import ProfileIcon from "../../assets/navbarMenus/pfofileIcons/ProfileIcon";
import MessagesIcon from "../../assets/navbarMenus/pfofileIcons/MessagesIcon";
import TasksIcon from "../../assets/navbarMenus/pfofileIcons/TasksIcon";

import logoutIcon from "../../assets/navbarMenus/pfofileIcons/logoutOutlined.svg";
import settingsIcon from '../../assets/settings-icon.svg'
import basketIcon from "../../assets/navbarMenus/basketIcon.svg";
import calendarIcon from "../../assets/navbarMenus/calendarIcon.svg";
import envelopeIcon from "../../assets/navbarMenus/envelopeIcon.svg";
import mariaImage from "../../assets/navbarMenus/mariaImage.jpg";
import notificationImage from "../../assets/navbarMenus/notificationImage.jpg";
import userImg from "../../assets/user.svg";

import Logo from "../../components/Icons/Logo.js"
import minLogo from "../../components/Icons/logo-mini.svg";
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator.js'

import "eva-icons/style/eva-icons.css";
import "pretty-checkbox/src/pretty-checkbox.scss"

import switchOff from "../../components/Icons/reports-off.svg";

import s from "./Header.module.scss";
import "animate.css";
import { Link } from "react-router-dom";
import Backdrop from "../SidebarDrawer/Backdrop.js";
import SidebarDrawer from "../SidebarDrawer/SidebarDrawer.js";
import ProfileMenuSidebarWrapper from "../SidebarDrawer/ProfileMenuSidebarWrapper.js";

const Header = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [reportsMode, setReportsMode] = useState(false);
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [isReportsMode, setIsReportsMode] = useState(false);
  const [creatingProject, setCreatingProject] = useState(false);
  const [newProjectName, setNewProjectName] = useState("");
  const [isSidebarOpened, setOpenSidebar] = useState(false);

  const history = useHistory();

  useEffect(() => {
    props.dispatch(getNotifications());

    console.log("Header: useEffect");
    setInterval(() => {
      props.dispatch(getNotifications());
    }, 5000);
  }, []);

  useEffect(() => {
    if (location.pathname.match(/dashboard/) || location.pathname.match(/project/)) {
      let projectId = props.location.pathname.split("/").pop()

      if (projectId == "" || projectId == 'dashboard' || projectId == 'project') {
        if (props.selectedProjectId != null) {
          if (location.pathname.match(/dashboard/)) {
            history.push(`/app/dashboard/${props.selectedProjectId}`)
          } else if (location.pathname.match(/project/)) {
            history.push(`/app/project/${props.selectedProjectId}`)
          }
        }

        return;
      }

      if (projectId == props.selectedProjectId) {
        return;
      }

      props.dispatch({
        type: 'SELECT_PROJECT',
        projectId
      })
    }
  }, [props.location])

  useEffect(() => {
    if ((props.selectedProjectId == null || props.selectedProjectId == "") && props.projects.length > 0) {
      let projectId = props.projects[0].shortName ?? props.projects[0]._id

      props.dispatch({
        type: 'SELECT_PROJECT',
        projectId
      })

      if (location.pathname.match(/dashboard/)) {
        history.push(`/app/dashboard/${projectId}`)
      } else if (location.pathname.match(/project/)) {
        history.push(`/app/project/${projectId}`)
      }
    }
  }, [props.projects])

  let location = useLocation();
  if (location.pathname.match(/search/)) {
    return (<></>);
  }

  if (location.pathname.match(/reports/) && isReportsMode === false) {
    setIsReportsMode(true);
  } else if (!location.pathname.match(/reports/) && isReportsMode === true) {
    setIsReportsMode(false)
  }

  const toggleNotifications = () => {
    setNotificationsOpen(!notificationsOpen);
  }

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  }

  // const toggleSidebar = () => {
  //   if (props.sidebarOpened) {
  //     props.dispatch(closeSidebar());
  //   } else {
  //     const paths = props.location.pathname.split('/');
  //     paths.pop();
  //     props.dispatch(openSidebar());
  //   }
  // }

  const doLogout = () => {
    props.dispatch(logoutUser());
  }

  const openProject = (projectIndex) => {
    let projectId = props.projects[projectIndex].shortName ?? props.projects[projectIndex]._id;
    history.push(`/app/project/${projectId}`);

    props.dispatch({
      type: 'SELECT_PROJECT',
      projectId
    })
  }

  const selectProject = (projectIndex, changeLocation) => {
    let projectId = props.projects[projectIndex].shortName ?? props.projects[projectIndex]._id;

    props.dispatch({
      type: 'SELECT_PROJECT',
      projectId
    })

    history.push(`/app/dashboard/${projectId}`);
  }

  const toggleReportsMode = () => {
    if (isReportsMode) {
      history.push(`/app/dashboard/${props.selectedProjectId}`);
    } else {
      history.push(`/app/reports/${props.selectedProjectId}`);
    }
  }

  const createProjectAction = () => {
    props.dispatch(createProject(newProjectName, () => {
      setCreatingProject(false);
      setNewProjectName("");

      selectProject(props.projects.length - 1, true);
    }))
  }

  //setNewUserDraft({ ...newUserDraft, email: e.target.value }
  const renderCreateProject = () => {
    return (
      <Modal isOpen={creatingProject} toggle={() => setCreatingProject(!creatingProject)}>
        <ModalHeader>
          <Translate id="project.create_project" />
        </ModalHeader>
        <ModalBody>
          <FormText className="mb-1"><Translate id="project.project_name" /></FormText>
          <Input required={true} value={newProjectName} onChange={(e) => { setNewProjectName(e.target.value) }} />
        </ModalBody>
        <ModalFooter >
          <Button color="secondary" onClick={() => setCreatingProject(false)}><Translate id="project.cancel" /></Button>
          <Button color="primary" onClick={() => { createProjectAction() }}><Translate id="project.create" /></Button>
        </ModalFooter>
      </Modal>
    )
  }

  const renderProjectSelector = (project, index, cb) => {
    if (project.access == null || project.access == undefined) return (<></>)
    if (props.user == null || props.user == undefined) return (<></>)
    if (props.user._id == null || props.user._id == undefined) return (<></>)

    let projectAcccess = project.access.find(x => x.user._id != undefined && x.user._id.toString() == props.user._id.toString())

    if (projectAcccess != null && projectAcccess != undefined && projectAcccess.access == "admin") {
      return (
        <div key={index} className={`${s.dropdownProfileContainerWithSettings}`}>
          <div key={index} className={`${s.profileMenuItem} ${s.dropdownProfileItem} ${s.dropdownProfileItemWithSettings} d-flex justify-content-between`} onClick={() => { selectProject(index, true); cb?.(); } }><span className='ml-0'>{project.name}</span>
          </div>
          {/* <i className={`eva eva-settings-2-outline ${s.settingsIcon}`} onClick={(e) => { */}
          <img className={`${s.settingsIcon}`} src={settingsIcon} onClick={(e) => {
            setMenuOpen(false)
            openProject(index)
            cb?.()
          }} />
        </div>
      )
    } else {
      return (
        <div key={index} className={`${s.profileMenuItem} ${s.dropdownProfileItem} d-flex justify-content-between`} onClick={() => { selectProject(index, true); cb?.(); }}><span className='ml-0'>{project.name}</span></div>
      )
    }
  }

  const renderProfileMenuSettings = (cb) => {
    return (
      <div onClick={() => { cb?.(); history.push('/app/profile'); }} className={`${s.profileMenuItem} d-flex justify-content-between align-itmes-center ${s.dropdownProfileItem}`}>
        <span className={`ml-0`}><Translate id="header.profile_settings" /></span>
      </div>
    )
  }

  const renderProfileMenuExit = (cb) => {
    return (
      <div onClick={() => { cb?.(); doLogout(); }} className={`${s.profileMenuItem} d-flex justify-content-between align-itmes-center ${s.dropdownProfileItem}`}>
        <span className={`ml-0 ${s.secondaryTextElement}`}><Translate id="header.logout" /></span>
        {/* <button className="btn btn-primary rounded-pill mx-auto logout-btn" type="submit"><img src={logoutIcon} alt="Logout" /><span className="ml-1"><Translate id="header.logout" /></span></button> */}
      </div>
    )
  }

  const renderCreatingNewProject = (cb) => {
    return (
      <div onClick={() => { cb?.(); setCreatingProject(true);}} className={`d-flex justify-content-between align-itmes-center ${s.profileMenuItem} ${s.dropdownProfileItem} ${s.createProjectDropdownItemParent}`}>
        <span className={`ml-0 ${s.createProjectDropdownItem}`}><Translate id="header.create_new_project" /></span><i className={`eva eva-plus-circle-outline ${s.createProjectDropdownItem}`} />
      </div>
    )
  }

  const renderSelectedProject = () => {
    if (props.projects == null) return (<LoadingIndicator />)

    let project = props.projects.find(x => x._id == props.selectedProjectId || x.shortName == props.selectedProjectId)
    if (project == null || project == undefined) {
      project = props.projects.find(x => x.shortName == props.selectedProjectId)

      console.log(props.projects[props.projects.length - 2])
      props.projects.forEach(x => {
        console.log(x.name, x.shortName, props.selectedProjectId, x.shortName == props.selectedProjectId)
      })
    }

    if (project == null || project == undefined) {
      console.log('check', props.selectedProjectId, props.projects)
      console.log('project not found')
    } else {
      console.log('project found')
    }

    if (project == null || project == undefined) return (<></>)

    return (<span>{project.name}</span>)
  }

  const toggleSidebar = () => {
    setOpenSidebar(!isSidebarOpened)
  }

  // TODO: Check mobile layout
  return (
    <Navbar className={`${s.root} d-print-none ${s.header} justify-content-between d-flex`}>

      {/* <div className='d-none'>
        <NavLink
          onClick={() => toggleSidebar()}
          className={`d-md-none mr-3 ${s.navItem}`}
          href="#"
        >
          <MenuIcon className={s.menuIcon} />
        </NavLink>
      </div> */}

      <div className="d-flex align-items-center">
        <div className="mr-4" to="/app/dashboard/" onClick={() => { window.location.href = "/app/dashboard" }}>
          {/* <Logo className={`${s.logo} ${s.pointer}`} /> */}
          <img src={minLogo} className={`${s.logo} ${s.pointer}`} />
        </div>

        <Translate>
          {({ translate }) => (
            <Toggle isSelected={isReportsMode} onClick={() => { toggleReportsMode() }} title={translate("header.reports_title")} />
          )}
        </Translate>

        {/* <label htmlFor="reports" className={`d-flex mb-0 mr-4 ${s.secondaryTextElement} ${s.pointer} justify-content-center align-items-center`}>
          <input id="reports" type="checkbox" checked={isReportsMode} onChange={() => { toggleReportsMode() }} />
          <p className="d-none d-md-block d-lg-block"><Translate id="header.reports_title" /></p>
        </label> */}

        <Link className={`${s.searchIconLink}`} to="/app/search">
          <i className={`eva eva-search-outline mr-4 ${s.secondaryTextElement} ${s.pointer}`} />
        </Link>
        {/* <i className={`eva eva-calendar-outline ${s.secondaryTextElement} ${s.pointer}`} /> */}
      </div>

      <div className="d-flex">
        <Dropdown nav isOpen={notificationsOpen} toggle={() => props.openNotificationsSidebar()} className="tutorial-dropdown mr-2 mr-sm-3">
          <DropdownToggle nav>
            <div className={s.navbarBlock}>
              <i className={'eva eva-bell-outline'} />
              { props.notifications.filter(x => x.isRead == false).length > 0 ? (<div className={s.count}></div>) : (<></>) }
            </div>
          </DropdownToggle>
        </Dropdown>

        {(props.projects == null) ? null :
          <div className="profile-menu">
            <Dropdown isOpen={menuOpen} toggle={() => toggleMenu()} nav id="basic-nav-dropdown" className="ml-2 d-none d-md-block
            ">
              <DropdownToggle nav caret className="navbar-dropdown-toggle">
                <span className="small ml-1 mr-2 body-1">
                  {renderSelectedProject()}
                </span>
              </DropdownToggle>
              <DropdownMenu className="navbar-dropdown profile-dropdown mr-3" style={{ width: "300px" }}>
                { renderCreatingNewProject() }

                {
                  props.projects.map((project, index) => {
                    return renderProjectSelector(project, index);
                  })
                }

                <div className="dropdown-divider" />

                { renderProfileMenuSettings() }
                { renderProfileMenuExit() }
                
              </DropdownMenu>
            </Dropdown>

            <div className="d-md-none">
              <div className={`${s.profileMenuButton}`} onClick={toggleSidebar}>
                <span className="small ml-1 mr-2 body-1">
                  {renderSelectedProject()}
                </span>
              </div>
            </div>
            <Backdrop show={isSidebarOpened} toggleSidebar={toggleSidebar} />
            <ProfileMenuSidebarWrapper show={isSidebarOpened} toggleSidebar={toggleSidebar}>
              <div className={`${s.profileMenu}`}>
                { renderCreatingNewProject(() => { setOpenSidebar(false); }) }

                {
                  props.projects.map((project, index) => {
                    return renderProjectSelector(project, index, () => { setOpenSidebar(false); });
                  })
                }

                <div className="dropdown-divider"></div>
                { renderProfileMenuSettings(() => { setOpenSidebar(false); }) }
                { renderProfileMenuExit(() => { setOpenSidebar(false); }) }
              </div>
            </ProfileMenuSidebarWrapper>
          </div>
        }

      </div>

      <Nav className="ml-auto d-none">
        <NavItem className="d-sm-none mr-4">
          <NavLink className="" href="#">
            <SearchIcon />
          </NavLink>
        </NavItem>
      </Nav>

      {renderCreateProject()}

    </Navbar>
  )
}

Header.propTypes = {
  dispatch: PropTypes.func.isRequired,
  sidebarOpened: PropTypes.bool,
}

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
    sidebarStatic: store.navigation.sidebarStatic,
    user: store.user.user,
    projects: store.projects.projects,
    selectedProjectId: store.navigation.selectedProjectId,
    notifications: store.notifications.notifications,
  };
}

export default withRouter(connect(mapStateToProps)(Header));

