import { combineReducers } from "redux";
import navigation from "./navigation.js";
import register from "./register.js";
import auth from "./auth.js";
import user from "./user.js";
import projects from './projects.js'
import notifications from './notifications.js'

export default combineReducers({
  register,
  auth,
  navigation,
  user,
  projects,
  notifications
});