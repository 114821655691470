import React from "react";
import { Link } from "react-router-dom";
import { Translate } from "react-localize-redux";

import s from "./SidebarDrawer.module.scss";
import SidebarDrawer from "./SidebarDrawer";

const ProfileMenuSidebarWrapper = (props) => {
    return (
        <SidebarDrawer show={props.show} toggleSidebar={props.toggleSidebar}>
            <div className={s.drawerContent}>
                <div className='d-flex flex-row'>
                    <div className={`${s.drawerTitle}`}><Translate id="header.profile"/></div>
                    <div className={`${s.closeButtonRight}`} onClick={props.toggleSidebar}>
                        <i className={`eva eva-close-outline ${s.evaBigSize}`}/>
                    </div>
                </div>
                <div className="d-flex text-center flex-column">
                    {props.children}
                </div>
            </div>
        </SidebarDrawer>
    )
}

export default ProfileMenuSidebarWrapper