// -- React and related libs
import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Switch, Route, withRouter, Redirect } from "react-router";

// -- Third Party Libs
import PropTypes from "prop-types";
import { Translate } from "react-localize-redux";

// -- Custom Components
import Header from "../Header/Header";
// import Sidebar from "../Sidebar/Sidebar";
import Footer from "../Footer/Footer";
import Breadcrumbs from "../Breadbrumbs/Breadcrumbs";
import Dashboard from "../../pages/dashboard/Dashboard";
// import Typography from "../../pages/typography/Typography";
import Notifications from "../../pages/notifications/Notifications";
import Tables from "../../pages/tables/Tables";
// import Charts from "../../pages/uielements/charts/Charts";
// import Icons from "../../pages/uielements/icons/IconsPage";
// import Maps from "../../pages/uielements/maps/google/GoogleMapPage";
import ProjectSettings from "../../pages/projectSettings/ProjectSettings";
import Reports from "../../pages/reports/Reports";
import Profile from "../../pages/profile/Profile";

import SearchPage from "../../pages/search/Search";
import uuid from "uuid";

// -- Component Styles
import s from "./Layout.module.scss";
import NotificationsSidebarWrapper from "../SidebarDrawer/NotificationsSidebarWrapper";
import Backdrop from "../SidebarDrawer/Backdrop";

const Layout = (props) => {

  const [isSidebarOpened, setOpenSidebar] = useState(false);

  const toggleSidebar = () => {
    setOpenSidebar(!isSidebarOpened)
  }

  useEffect(() => {
    if (isSidebarOpened) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [isSidebarOpened])

  return (
    <div className={s.root}>
      <div className={s.wrap}>
        
        <Header openNotificationsSidebar={toggleSidebar}/>
        <Backdrop show={isSidebarOpened} toggleSidebar={toggleSidebar} />
        <NotificationsSidebarWrapper show={isSidebarOpened} toggleSidebar={toggleSidebar}/>
        
        <main className={s.content}>

          <Switch>
            <Route path="/app/search" exact component={SearchPage} />

            <Route path="/app" exact render={() => <Redirect to="app/dashboard" />} />
            <Route path="/app/dashboard" exact component={Dashboard} />

            <Route path="/app/dashboard" component={Dashboard} />
            <Route path="/app/profile" component={Profile} />

            <Route path="/app/project/*" component={ProjectSettings} />
            <Route path="/app/reports/*" component={Reports} />
            {/* <Route path="/template/typography" exact component={Typography} /> */}
            {/* <Route path="/template/tables" exact component={Tables} /> */}
            <Route path="/template/notifications" exact component={Notifications} />

            {/* <Route path="/template/ui-elements" exact render={() => <Redirect to={"/template/ui-elements/charts"} />} /> */}
            {/* <Route path="/app/template/ui-elements/charts" exact component={Charts} /> */}
            {/* <Route path="/template/ui-elements/icons" exact component={Icons} /> */}
            {/* <Route path="/template/ui-elements/maps" exact component={Maps} /> */}
            <Route path='*' exact render={() => <Redirect to="/error" />} />


          </Switch>
        </main>
        <Footer />

      </div>
    </div>

  );
}

Layout.propTypes = {
  sidebarOpened: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
}

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
  };
}

export default withRouter(connect(mapStateToProps)(Layout));
