import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter, Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  FormText,
  Input,
} from "reactstrap";
import Widget from "../../components/Widget/Widget.js";
import Footer from "../../components/Footer/Footer.js";
import Logo from "../../components/Icons/Logo.js"
import s from "./Register.module.scss";
import arrowWhite from "../../assets/arrow-white.svg";

import { registerUser } from "../../actions/register.js";
import hasToken from "../../services/authService";
import { Translate } from "react-localize-redux";
import icon from "../../assets/registration-icon.png";

const Register = (props) => {
  const [state, setState] = useState({ email: '', password: '', projectName: '' })

  const changeCred = (event) => {
    setState({ ...state, [event.target.name]: event.target.value })
  }

  const doRegister = (event) => {
    event.preventDefault();
    props.dispatch(registerUser({
      creds: state,
      history: props.history,
    }))
  }

  const { from } = props.location.state || { from: { pathname: '/app' } }

  if (hasToken(JSON.parse(localStorage.getItem('authenticated')))) {
    return (
      <Redirect to={from} />
    );
  }

  return (
    <div className="auth-page">
      <Container className="col-12">
        <div className={`${s.ladingHeader}`}>
          <div className={`${s.ladingHeaderContent}`}>
            <Link to="/" replace>
              <Logo className={`${s.logo} ${s.pointer}`} />
            </Link>
            <div className={`${s.buttonsContainer}`}>
              <Translate id="sign_up.already_have_account" />
              <Link className={`${s.registerLink}`} to="/login">
                <Translate id="sign_up.login" />
              </Link>
            </div>
          </div>
        </div>
        <div className={`${s.pageContainer}`}>
          <div className={`${s.contentContainer}`}>
            <Widget className="widget-auth widget-p-lg">
              <div className="d-flex align-items-center justify-content-between">
                <p className="auth-header mb-0"><Translate id="sign_up.sign_up_title" /><span><img className={s.titleIcon} src={icon}/></span></p>
              </div>
              <form onSubmit={(event => doRegister(event))}>
                <FormGroup className="my-3">
                  <FormText className="form-title">
                    <Translate id="sign_up.email" />
                  </FormText>

                  <Translate>
                    {({ translate }) => <Input
                      id="email"
                      className="input-transparent pl-3"
                      value={state.email}
                      onChange={(event) => changeCred(event)}
                      type="email"
                      required
                      name="email"
                      placeholder={translate('sign_up.email_placeholder')} />}
                  </Translate>
                </FormGroup>

                <FormGroup className="my-3">
                  <FormText className="form-title">
                    <Translate id="sign_up.password" />
                  </FormText>
                  <Translate>
                    {({ translate }) => <Input
                      id="password"
                      className="input-transparent pl-3"
                      value={state.password}
                      onChange={(event => changeCred(event))}
                      type="password"
                      required
                      name="password"
                      placeholder={translate('sign_up.password_placeholder')} />}
                  </Translate>
                </FormGroup>

                <FormGroup className="my-3">
                  <FormText className="form-title">
                    <Translate id="sign_up.project_name" />
                  </FormText>
                  <Translate>
                    {({ translate }) => <Input
                      id="projectName"
                      className="input-transparent pl-3"
                      value={state.projectName}
                      onChange={(event => changeCred(event))}
                      type="text"
                      required
                      name="projectName"
                      placeholder={translate('sign_up.project_name_placeholder')} />}
                  </Translate>
                </FormGroup>

                {(props.errorMessage) ? (
                  <div className="alert alert-danger">
                    {props.errorMessage}
                  </div>) : null}
                {(props.isFetching) ? (
                  // loading indicator
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only"><Translate id="sign_up.loading" /></span>
                  </div>
                ) : (
                  <div className="bg-widget d-flex justify-content-start">
                    <Button className={`${s.tryButton}`} type="submit" color="secondary-red">
                      <Translate id="sign_up.sign_up_button" />
                      <img className={`${s.arrow}`} src={arrowWhite} alt="arrow" />
                    </Button>
                  </div>
                )}

              </form>

            </Widget>
            <Footer />
          </div>
        </div>
      </Container >
    </div >
  )
}

Register.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  console.log(state)
  return {
    isFetching: state.register.isFetching,
    isAuthenticated: state.auth.isAuthenticated,
    errorMessage: state.register.errorMessage,
  };
}

export default withRouter(connect(mapStateToProps)(Register));
