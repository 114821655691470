import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter, Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Button,
  Container,
} from "reactstrap";
import Widget from "../../components/Widget/Widget";
import Footer from "../../components/Footer/Footer";
import Tag from "../../components/Tag/Tag";

import { loginUser, getToken, clearAuthErrors } from "../../actions/auth";
import hasToken from "../../services/authService";

import Logo from "../../components/Icons/Logo.js"

import s from "./Landing.module.scss";

import { withLocalize, Translate } from "react-localize-redux";

import demoImage from "../../assets/demo-content.png";
import arrowWhite from "../../assets/arrow-white.svg";
import arrowBlack from "../../assets/arrow-black.svg";
import landingArrow from "../../assets/landing-arrow.svg";

const Landing = (props) => {

  const [state, setState] = useState({
    email: '',
    password: '',
  })

  const doLogin = (e) => {
    e.preventDefault();
    props.dispatch(loginUser({ password: state.password, email: state.email }))
  }

  const doGetToken = () => {
    props.dispatch(getToken())
  }

  const changeCreds = (event) => {
    props.dispatch(clearAuthErrors());
    setState({ ...state, [event.target.name]: event.target.value })
  }

  const { from } = props.location.state || { from: { pathname: '/app' } };

  useEffect(() => {
    if (localStorage.getItem('token') == null) {
      console.log('no token')
      doGetToken()
    }
  }, [])

  useEffect(() => {
    if (props.isAuthenticated) {
      props.history.replace(from);
    }
  }, [props.isAuthenticated])

  if (hasToken(JSON.parse(localStorage.getItem('authenticated')))) {
    return (
      <Redirect to={from} />
    )
  }

  function setActiveLanguage(code) {
    console.log(code)
    props.setActiveLanguage(code);
    localStorage.setItem('currentLanguageCode', code);
  }

  const LanguageToggle = ({ languages, setActiveLanguage }) => {
    let currentLanguageCode = localStorage.getItem('currentLanguageCode')
    return (
      <div className={`d-flex flex-row flex-wrap ml-4 ${s.gap10} ${s.languages}`}>
        {
          languages.map(lang => (
            <Tag title={lang.name} isSelected={lang.code == currentLanguageCode} onClick={() => setActiveLanguage(lang.code)} />
          ))
        }
      </div>
    )
  }

  return (
    <div className="auth-page">
      <Container className="col-12">
        <div className={`${s.ladingHeader}`}>
          <div className={`${s.ladingHeaderContent}`}>
            <div className="d-flex">
              <Logo className={`${s.logo} ${s.pointer}`} />
            </div>

            <div className={`${s.buttonsContainer}`}>
              <Link className={`${s.loginButton}`} to="/login/" replace>
                <Translate id="landing.login" />
              </Link>
              <Link to="/register/" replace>
                <Button className={`${s.registerButton}`}>
                  <Translate id="landing.register" />
                  <img className={`${s.arrow}`} src={arrowBlack} alt="arrow" />
                </Button>
              </Link>
            </div>
          </div>
        </div>
        <div className={`${s.pageContainer}`}>
          <div className={`${s.contentContainer}`}>
            <div className={`${s.leftColumn}`}>
              <p className={`${s.title}`}>
                <Translate id="landing.title" />
              </p>
              <p className={`${s.description}`}>
                <Translate id="landing.description" />
              </p>
              <div className="position-relative">
                <Link className="mr-4" to="/register/" replace>
                  <Button className={`${s.tryButton}`}>
                    <Translate id="landing.tryButton" />
                    <img className={`${s.arrow}`} src={arrowWhite} alt="arrow" />
                  </Button>
                </Link>

                <img className={s.tryArrow} src={landingArrow} alt="landing arrow" />
                <span className={s.tryDescription}><Translate id="landing.freeProject" /></span>
              </div>
            </div>
            <div className={`${s.rightColumn}`}>
              <img className={`${s.demoImage}`} src={demoImage} alt="Demo Content" />
            </div>
          </div>
          <Footer />
        </div>
      </Container>

      <LanguageToggle languages={[
        { name: 'English', code: 'en' },
        { name: 'Русский', code: 'ru' }
      ]} setActiveLanguage={(code) => setActiveLanguage(code)} />
    </div>
  )
}

Landing.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    isFetching: state.auth.isFetching,
    isAuthenticated: state.auth.isAuthenticated,
    errorMessage: state.auth.errorMessage,
  };
}

export default withLocalize(withRouter(connect(mapStateToProps)(Landing)));
