import { toast } from 'react-toastify';
import axios from 'axios';

import { UPDATE_USER } from './user';
import Notification from "../components/Notification/Notification.js"; 

export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

export function receiveRegister() {
  return {
    type: REGISTER_SUCCESS,
  };
}

export function registerError(payload) {
  return {
    type: REGISTER_FAILURE,
    payload,
  };
}

export function receiveUserUpdate(user) {
  return {
    type: UPDATE_USER,
    user,
  };
}

export function registerUser(payload) {
  return (dispatch) => {
    const url = `${process.env.REACT_APP_SERVER_IP}/api/user/register`;

    axios.post(url, {
      email: payload.creds.email,
      password: payload.creds.password,
      projectName: payload.creds.projectName,
      token: localStorage.getItem('token'),
      language: localStorage.getItem('currentLanguageCode')
    }).then((response) => {
      console.log(response.data);

      if (response.data.success) {
        localStorage.setItem('user', JSON.stringify(response.data.session.user))
        localStorage.setItem('token', response.data.session.token)
        localStorage.setItem ('authenticated', true)

        dispatch(receiveUserUpdate(response.data.session.user));
        dispatch(receiveRegister());

        // toast.success('Registration successful');

        // const options = {
        //   autoClose: 4000,
        //   closeButton: false,
        //   hideProgressBar: true,
        //   position: toast.POSITION.TOP_RIGHT,
        // };

        // toast(<Notification type="success"/>, options);
      } else {
        dispatch(registerError(response.data.error));
        // toast.error(response.data.message);
      }
    }).catch((error) => {
      console.log(JSON.stringify(error))

      dispatch(registerError(error));
      toast.error(error);
    });
  }
}
