import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter, Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  FormText,
  Input,
} from "reactstrap";
import Widget from "../../components/Widget/Widget";
import Footer from "../../components/Footer/Footer";
import { loginUser, getToken, clearAuthErrors } from "../../actions/auth";
import hasToken from "../../services/authService";

import Logo from "../../components/Icons/Logo.js"
import s from "./Login.module.scss";
import arrowWhite from "../../assets/arrow-white.svg";

import { Translate } from "react-localize-redux";
import icon from "../../assets/login-icon.png";

const Login = (props) => {

  const [state, setState] = useState({
    email: '',
    password: '',
  })

  const doLogin = (e) => {
    e.preventDefault();
    props.dispatch(loginUser({ password: state.password, email: state.email }))
  }

  const doGetToken = () => {
    props.dispatch(getToken())
  }

  const changeCreds = (event) => {
    props.dispatch(clearAuthErrors());
    setState({ ...state, [event.target.name]: event.target.value })
  }

  const { from } = props.location.state || { from: { pathname: '/app' } };

  useEffect(() => {
    console.log('use effect')
    if (localStorage.getItem('token') == null) {
      console.log('no token')
      doGetToken()
    }
  }, [])

  if (hasToken(JSON.parse(localStorage.getItem('authenticated')))) {
    return (
      <Redirect to={from} />
    )
  }

  return (
    <div className="auth-page">
      <Container className="col-12">
        <div className={`${s.ladingHeader}`}>
          <div className={`${s.ladingHeaderContent}`}>
            <Link to="/" replace>
              <Logo className={`${s.logo} ${s.pointer}`} />
            </Link>
            <div className={`${s.buttonsContainer}`}>
              <Translate id="login.dont_have_account" />
              <Link className={`${s.registerLink}`} to="/register">
                <Translate id="login.register" />
              </Link>
            </div>
          </div>
        </div>
        <div className={`${s.pageContainer}`}>
          <div className={`${s.contentContainer}`}>
            <Widget className="widget-auth widget-p-lg">
              <div className="d-flex align-items-center justify-content-between">
                <p className="auth-header mb-0"><Translate id="login.login_title" /><span><img className={s.titleIcon} src={icon}/></span></p>
              </div>
              <form onSubmit={(event) => doLogin(event)}>
                <FormGroup className="my-3">
                  <FormText className="form-title"><Translate id="login.email" /></FormText>
                  <Translate>
                    {({ translate }) =>
                      <Input
                        id="email"
                        className="input-transparent pl-3"
                        value={state.email}
                        onChange={(event) => changeCreds(event)}
                        type="email"
                        required
                        name="email"
                        placeholder={translate('login.email_placeholder')}
                      />
                    }
                  </Translate>
                </FormGroup>
                <FormGroup className="my-3">
                  <div className="d-flex justify-content-between">
                    <FormText className="form-title"><Translate id="login.password" /></FormText>
                    <Link className="form-link" to="/restorePassword"><Translate id="login.forgot_password" /></Link>
                  </div>
                  <Translate>
                    {({ translate }) =>
                      <Input
                        id="password"
                        className="input-transparent pl-3"
                        value={state.password}
                        onChange={(event) => changeCreds(event)}
                        type="password"
                        required
                        name="password"
                        placeholder={translate('login.password_placeholder')}
                      />
                    }
                  </Translate>
                </FormGroup>
                {(props.errorMessage) ? (
                  <div className="alert alert-danger">
                    {
                      typeof(props.errorMessage) == 'string' ? (
                        <div key={0}>{props.errorMessage}</div>
                        ) : /*props.errorMessage.map((error, index) => {*/
                        // return (
                          JSON.stringify(props.errorMessage)
                          // <div key={0}>{typeof(props.errorMessage)}</div>
                        // )
                      // })
                    }
                  </div>) : null
                }
                <div className="bg-widget d-flex justify-content-start">
                  <Button className={`${s.tryButton}`} type="submit" color="secondary-red">
                    <Translate id="login.login_button" />
                    <img className={`${s.arrow}`} src={arrowWhite} alt="arrow" />
                  </Button>
                </div>
              </form>
            </Widget>
          </div>
          <Footer />
        </div>
      </Container>
    </div>
  );

  // <div className="auth-page">
  //   <Container className="col-12">
  //     <Row className="d-flex align-items-center">
  //       <Col xs={12} lg={6} className="left-column">
  //         <Widget className="widget-auth widget-p-lg">
  //           <div className="d-flex align-items-center justify-content-between py-3">
  //             <p className="auth-header mb-0"><Translate id="login.login_title"/></p>
  //             <div className="logo-block">
  //               <SofiaLogo />
  //               {/* <p className="mb-0">BLABJORN Analytics</p> */}
  //             </div>
  //           </div>
  //           <form onSubmit={(event) => doLogin(event)}>
  //             <FormGroup className="my-3">
  //               <FormText><Translate id="login.email"/></FormText>
  //               <Translate>
  //                 {({translate}) =>
  //                   <Input
  //                     id="email"
  //                     className="input-transparent pl-3"
  //                     value={state.email}
  //                     onChange={(event) => changeCreds(event)}
  //                     type="email"
  //                     required
  //                     name="email"
  //                     placeholder={translate('login.email_placeholder')}
  //                   />
  //                 }
  //               </Translate>
  //             </FormGroup>
  //             <FormGroup  className="my-3">
  //               <div className="d-flex justify-content-between">
  //                 <FormText><Translate id="login.password"/></FormText>
  //                 <Link to="/error"><Translate id="login.forgot_password"/></Link>
  //               </div>
  //               <Translate>
  //                 {({translate}) =>
  //                   <Input
  //                     id="password"
  //                     className="input-transparent pl-3"
  //                     value={state.password}
  //                     onChange={(event) => changeCreds(event)}
  //                     type="password"
  //                     required
  //                     name="password"
  //                     placeholder={translate('login.password_placeholder')}
  //                   />
  //                 }
  //               </Translate>
  //             </FormGroup>
  //             {(props.errorMessage) ? (
  //               <div className="alert alert-danger">
  //                 {props.errorMessage}
  //               </div>) : null 
  //             }
  //             <div className="bg-widget d-flex justify-content-center">
  //               <Button className="rounded-pill my-3" type="submit" color="secondary-red"><Translate id="login.login_button"/></Button>
  //             </div>
  //             <p className="dividing-line my-3"><Translate id="login.or"/></p>
  //             <div className="d-flex align-items-center my-3">
  //               <p className="social-label mb-0"><Translate id="login.login_with"/></p>
  //               <div className="socials">
  //                 <a href="https://google.com/"><GoogleIcon /></a>
  //                 {/* <a href="https://flatlogic.com/"><TwitterIcon /></a> */}
  //                 <a href="https://facebook.com/"><FacebookIcon /></a>
  //                 {/* <a href="https://flatlogic.com/"><GithubIcon /></a> */}
  //                 {/* <a href="https://flatlogic.com/"><LinkedinIcon /></a> */}
  //               </div>
  //             </div>
  //             <Link to="/register"><Translate id="login.dont_have_account"/></Link>
  //           </form>
  //         </Widget>
  //       </Col>
  //       <Col xs={0} lg={6} className="right-column">
  //         <div>
  //           <img src={loginImage} alt="Error page" />
  //         </div>
  //       </Col>
  //     </Row>
  //   </Container>
  //   <Footer />
  // </div>
  // )
}

Login.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    isFetching: state.auth.isFetching,
    isAuthenticated: state.auth.isAuthenticated,
    errorMessage: state.auth.errorMessage,
  };
}

export default withRouter(connect(mapStateToProps)(Login));
