import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes, { func } from "prop-types";
import { withLocalize, Translate } from "react-localize-redux";
import { useHistory } from "react-router";

import {
    getProject,
    inviteToProject,
    resendInvite,
    cancelInvite,
    editProjectName,
    editProjectAccess,
    setProjectShortName,
    removeProjectAccess,
    deleteProject
} from "../../actions/projects.js";

import { auth } from "../../actions/user";

import { Button, ButtonDropdown, DropdownToggle, DropdownItem, DropdownMenu, FormText, Label, Input, InputGroup, InputGroupText, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';

import s from "./ProjectSettings.module.scss"

import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator.js";

const accessTypes = [
    "admin",
    "write",
    "read"
]

const ProjectSettings = (props) => {
    // const [projectId, setProjectId] = useState(null);
    const [project, setProject] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [openedButtonIndex, setOpenedButtonIndex] = useState(null);
    const [isAddingAccess, setIsAddingAccess] = useState(false);
    const [isDeletingProject, setIsDeletingProject] = useState(false);
    const [newUserDraft, setNewUserDraft] = useState({
        email: "",
        role: "write"
    });
    const [oldShortName, setOldShortName] = useState("");
    const [editShortNameError, setEditShortNameError] = useState(null);
    const [showAddtionalButtons, setShowAddtionalButtons] = useState(false);

    const history = useHistory();

    useEffect(() => {
        props.dispatch(getProject(props.projectId, (project, tags) => {
            setProject(project);
            setOldShortName(project.shortName);
        }))
    }, [props.projectId])

    useEffect(() => {
        props.dispatch(auth())

        //     const projectId = props.location.pathname.split("/").pop()
        //     setProjectId(projectId)

        //     let projectIndex = props.allProjects.findIndex(project => project._id === projectId)
        //     if (projectIndex !== -1 && props.selectedProjectIndex !== projectIndex) {
        //         props.dispatch({
        //             type: 'SELECT_PROJECT',
        //             index: projectIndex
        //         })
        //     }

        // props.dispatch(getProject(projectId, (project, tags) => {
        //     setProject(project);
        // }))
    }, [])

    // useEffect(() => {
    //     let projectIndex = props.allProjects.findIndex(project => project._id === projectId)
    //     if (projectIndex !== -1 && props.selectedProjectIndex !== projectIndex) {
    //         props.dispatch({
    //             type: 'SELECT_PROJECT',
    //             index: projectIndex
    //         })
    //     } 

    //     if (projectIndex > -1) {
    //         console.log("All projects changed")
    //         setProject(props.allProjects[projectIndex]);
    //     }
    // }, [props.allProjects])
    // var newProjectId = props.location.pathname.split("/").pop()
    // if (newProjectId !== projectId) {
    //     setProjectId(newProjectId)
    //     setProject(null)

    //     let projectIndex = props.allProjects.findIndex(project => project.id === projectId)
    //     if (projectIndex !== -1 && props.selectedProjectIndex !== projectIndex) {
    //         props.dispatch({
    //             type: 'SELECT_PROJECT',
    //             index: projectIndex
    //         })
    //     }

    //     props.dispatch(getProject(newProjectId, (project, tags) => {
    //         setProject(project);
    //     }))
    // }

    const handleNameChange = (e) => {
        setProject({ ...project, name: e.target.value })
    }

    const handleShortNameChange = (e) => {
        setEditShortNameError(null);
        setProject({ ...project, shortName: e.target.value });
    }

    function sendInviteAction() {
        props.dispatch(inviteToProject(props.projectId, newUserDraft.email, newUserDraft.role, () => {
            setIsAddingAccess(false);
        }))
    }

    function resendInviteAction(invite) {
        props.dispatch(resendInvite(props.projectId, invite._id, () => {
            setIsAddingAccess(false);
        }))
    }

    function cancelInviteAction(invite) {
        props.dispatch(cancelInvite(props.projectId, invite._id, () => { }))
    }

    function editProjectAction() {
        props.dispatch(editProjectName(project.name, project._id, () => { }))

        console.log(project.shortName, oldShortName, project)
        if (oldShortName !== project.shortName) {
            props.dispatch(setProjectShortName(project.shortName, project._id, (error) => {
                if (error != null) {
                    setEditShortNameError(error);
                } else {
                    history.push("/app/project/" + project.shortName);

                    props.dispatch({
                        type: 'SELECT_PROJECT',
                        projectId: project.shortName
                    })
                }
            }))
        }
    }

    function editProjectAccessAction(accessId, type) {
        props.dispatch(editProjectAccess(project._id, accessId, type, () => { }))
    }

    function removeProjectAccessAction(accessId) {
        props.dispatch(removeProjectAccess(project._id, accessId, () => { }))
    }

    function deleteProjectAction() {
        props.dispatch(deleteProject(project._id, (success) => {
            if (success) {
                props.dispatch({
                    type: 'SELECT_PROJECT',
                    projectId: null
                })

                history.push("/app/dashboard");
            }
        }))
    }

    const renderDeleteProjectModal = () => {
        return (
            <Modal isOpen={isDeletingProject} toggle={() => setIsDeletingProject(!isDeletingProject)}>
                <ModalHeader>
                    <Translate id="settings.delete_project_title" />
                </ModalHeader>
                <ModalFooter >
                    <Button color="secondary" onClick={() => setIsDeletingProject(false)}><Translate id="settings.cancel" /></Button>
                    <Button color="danger" onClick={() => deleteProjectAction()}><Translate id="settings.delete" /></Button>
                </ModalFooter>
            </Modal>
        )
    }

    const renderUsersWithAccess = () => {
        if (props.user == null || project == null || project.access == null) return null;
        
        return (
            <div className={`d-flex flex-column ${s.prjectContainer} ${s.gap10}`}>
                <h5 className={s.rootTitle}> <Translate id="settings.project_access" /> </h5>

                <div className={`d-flex flex-column ${s.gap10} ${s.hideLast}`}>
                    {
                        project.access.map((access, index) => {
                            return (
                                <>
                                    <div key={index} className={`d-flex justify-content-between ${s.userWithAccess}`}>
                                        <p>
                                            {access.user.email}
                                            {
                                                access.user._id === props.user._id ?
                                                    (" (you)") : (null)
                                            }
                                        </p>
                                        {
                                            access.user._id === project.owner._id ?
                                                (" 👑 ") : (null)
                                        }
                                        {
                                            access.user._id !== props.user._id && access.user._id != project.owner._id ?
                                                (
                                                    <ButtonDropdown color="light" isOpen={index === openedButtonIndex} toggle={() => {
                                                        if (index === openedButtonIndex) {
                                                            setOpenedButtonIndex(null)
                                                            return
                                                        }

                                                        setOpenedButtonIndex(index)
                                                    }}>
                                                        <DropdownToggle caret>
                                                            {access.access}
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                            <DropdownItem header>
                                                                <Translate id="settings.change_permission" />
                                                            </DropdownItem>
                                                            {
                                                                accessTypes.map((type, index) => {
                                                                    return (
                                                                        type === access.access ? null :
                                                                            <DropdownItem key={index} onClick={() => { editProjectAccessAction(access._id, type) }}>
                                                                                {type}
                                                                            </DropdownItem>
                                                                    )
                                                                })
                                                            }
                                                            <DropdownItem divider />
                                                            <DropdownItem color="red" onClick={() => { removeProjectAccessAction(access._id) }}>
                                                                <Translate id="settings.remove_user" />
                                                            </DropdownItem>
                                                        </DropdownMenu>
                                                    </ButtonDropdown>
                                                ) : (null)
                                        }
                                    </div>
                                    <line className={s.separator} />
                                </>
                            )
                        })
                    }
                </div>
                <div></div>

                {renderInvites()}

                <div></div>
                <div className="d-flex flex-row justify-content-between">
                    <div></div>
                    <Button outline color="primary" onClick={() => setIsAddingAccess(true)}><Translate id="settings.invite_new_user" /></Button>
                </div>
            </div>
        )
    }

    const renderInvites = () => {
        if (props.invites[props.projectId] == undefined || props.invites[props.projectId].length == 0) return null;

        return (
            <>
                <h5 className={s.rootTitle}> <Translate id="settings.pending_invitations" /> </h5>

                <div className={`d-flex flex-column ${s.gap10} ${s.hideLast}`}>
                    {props.invites[props.projectId].map((invite, index) => {
                        return (
                            <>
                                <div className={`d-flex flex-row ${s.gap10} justify-content-between align-items-center ${s.userWithAccess}`} key={index}>
                                    <span>{invite.email} ({invite.access})</span>
                                    <div className={`d-flex flex-row ${s.gap10} justify-content-between align-items-center`}>
                                        <a className={s.resendLink} onClick={() => resendInviteAction(invite)}><Translate id="settings.resend" /></a> |
                                        <Button color="light" close size="sm" type="secondary" onClick={() => cancelInviteAction(invite)}></Button>
                                    </div>
                                </div>
                                <line className={s.separator} />
                            </>
                        )
                    })}
                </div>
            </>
        )
    }

    const renderIviteNewUserModal = () => {
        return (
            <Modal isOpen={isAddingAccess} toggle={() => setIsAddingAccess(!isAddingAccess)}>
                <ModalHeader>
                    <Translate id="settings.invite_new_user_title" />
                </ModalHeader>
                <ModalBody>
                    <FormText className="mb-1">Email:</FormText>
                    <Input required={true} onChange={(e) => setNewUserDraft({ ...newUserDraft, email: e.target.value })} />
                    <FormText className="mt-3 mb-1"><Translate id="settings.role" /></FormText>
                    <div className={`d-flex flex-row ${s.gap10}`}>
                        <div >
                            <Input className="ml-0 position-relative mr-2" type="radio" name="admin" id="admin" title="Income" checked={newUserDraft.role === "admin"} onClick={() => setNewUserDraft({ ...newUserDraft, role: "admin" })} />
                            <label htmlFor="admin"><Translate id="settings.admin_role" /></label>
                        </div>
                        <div>
                            <Input className="ml-0 position-relative mr-2" type="radio" name="write" id="write" title="Expense" checked={newUserDraft.role === "write"} onClick={() => setNewUserDraft({ ...newUserDraft, role: "write" })} />
                            <label htmlFor="write"><Translate id="settings.user_role" /></label>
                        </div>
                        <div>
                            <Input className="ml-0 position-relative mr-2" type="radio" name="read" id="read" title="Expense" checked={newUserDraft.role === "read"} onClick={() => setNewUserDraft({ ...newUserDraft, role: "read" })} />
                            <label htmlFor="read"><Translate id="settings.read_only_role" /></label>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter >
                    <Button color="secondary" onClick={() => setIsAddingAccess(false)}><Translate id="settings.cancel" /></Button>
                    <Button color="primary" onClick={() => sendInviteAction()}><Translate id="settings.invite" /></Button>
                </ModalFooter>
            </Modal>
        )
    }

    const renderProject = () => {
        return (
            <div className={`d-flex flex-column ${s.gap10}`}>
                <h5 className={s.rootTitle}> <Translate id="settings.common_settings" /> </h5>

                <div className={`d-flex flex-column ${s.prjectContainer} ${s.gap10}`}>
                    <ButtonDropdown className={`${s.optionsButton}`} color="light" isOpen={showAddtionalButtons} toggle={() => {
                        setShowAddtionalButtons(!showAddtionalButtons)
                    }}>
                        <DropdownToggle>
                            ...
                        </DropdownToggle>
                        <DropdownMenu right>
                            <DropdownItem className={s.danger} color="danger" onClick={() => setIsDeletingProject(true)}>
                                <Translate id="settings.remove"/>
                            </DropdownItem>
                        </DropdownMenu>
                    </ButtonDropdown>

                    <h5 className={s.rootTitle}> <Translate id="settings.common_settings" /> </h5>

                    <div className={`d-flex flex-column ${s.gap10} mb-3`}>
                        <label for="name"><Translate id="settings.project_name" /></label>
                        <Translate>
                            {({ translate }) =>
                                <Input
                                    id="name"
                                    className="input-transparent pl-3 w-100"
                                    value={project.name ?? ""}
                                    onChange={(event) => handleNameChange(event)}
                                    type="text"
                                    required
                                    name="Name"
                                    placeholder={translate('settings.project_name_placeholder')}
                                />

                            }
                        </Translate>

                        <label for="shortname"><Translate id="settings.short_name" /></label>
                        <Translate>
                            {({ translate }) =>
                                <Input
                                    id="shortname"
                                    className="input-transparent pl-3 w-100"
                                    value={project.shortName ?? ""}
                                    onChange={(event) => handleShortNameChange(event)}
                                    type="text"
                                    required
                                    name="Short name"
                                    placeholder={translate('settings.project_short_name_placeholder')}
                                />
                            }
                        </Translate>

                        {editShortNameError != null ? (<FormText color="danger">{editShortNameError}</FormText>) : (null)}
                    </div>

                    <div className="d-flex flex-row justify-content-between">
                        <div></div>
                        <Button color="primary" onClick={() => editProjectAction()}><Translate id="settings.save" /></Button>
                    </div>
                </div>

                {renderUsersWithAccess()}

                {renderIviteNewUserModal()}
                {renderDeleteProjectModal()}
            </div>
        )
    }

    return (
        <div className="w-100">
            {project == null ? (<LoadingIndicator />) : renderProject()}
        </div>
    )
}

ProjectSettings.propTypes = {
    dispatch: PropTypes.func.isRequired,
}

function mapStateToProps(store) {
    return {
        invites: store.projects.invites,
        allProjects: store.projects.projects,
        selectedProjectIndex: store.user.selectedProjectIndex,
        user: store.user.user,
        projectId: store.navigation.selectedProjectId
    }
}

export default withLocalize(withRouter(connect(mapStateToProps)(ProjectSettings)))