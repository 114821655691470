import React from "react";
import { Link } from "react-router-dom";
import { Translate } from "react-localize-redux";

import s from "./SidebarDrawer.module.scss";

const SidebarDrawer = (props) => {
    return (
        <div className={`${s.sideBarNew} side-bar-new ${props.show ? s.sideDrawerOpen : s.sideDrawer}`}>
            {props.children}
        </div>
    )
}

export default SidebarDrawer