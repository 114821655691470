import React from "react";
import { Link } from "react-router-dom";
import { Translate } from "react-localize-redux";

import s from "./ErrorPage.module.scss";

import {
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  FormText,
  Input,
} from "reactstrap";
import Widget from "../../components/Widget/Widget";
import Footer from "../../components/Footer/Footer";

const ErrorPage = () => {
  return (
    <div className="error-page">
      <Container className="col-12">
        <div className={`${s.pageContainer}`}>
          <div className={`${s.contentContainer}`}>
            <div className="d-flex text-center justify-content-center align-items-center flex-column" style={{ "minHeight": "80vh" }}>
              <h1>404</h1>
              <p className={s.errorInfo}>
                <Translate id="error.error_info" />
              </p>
              {/* <p className={s.errorHelp}>
                <Translate id="error.error_help" />
              </p> */}
              <Link to="/app/dashboard">
                <Button className={`${s.errorBtn}`} type="submit" color="primary">
                <Translate id="error.back_to_home" />
                </Button>
              </Link>
            </div>
          </div>
          <Footer />
        </div>
      </Container>
    </div>
    // <div className={s.pageContainer}>
    //   <div className={s.errorContainer}>
    //     <h1 className={s.errorCode}>404</h1>
    //     <p className={s.errorInfo}>
    //       Oops. Looks like the page you're looking for no longer exists
    //     </p>
    //     <p className={s.errorHelp}>
    //       But we're here to bring you back to safety
    //     </p>
    //     <Link to="/app/dashboard">
    //       <Button className={`${s.errorBtn} rounded-pill`} type="submit" color="secondary-red">
    //         Back to Home
    //       </Button>
    //     </Link>
    //   </div>
    //   <div className={s.imageContainer}>
    //     <img className={s.errorImage} src={errorImage} alt="Error page" width="80" />
    //   </div>
    //   <div className={s.footer}>
    //     <span className={s.footerLabel}>2021 &copy; Flatlogic. Hand-crafted & Made with</span>
    //     <FooterIcon />
    //   </div>
    // </div>
  );
}

export default ErrorPage;
