import React, { useEffect, useState } from "react";
import moment from "moment";

import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { useLocation, useHistory } from "react-router";
import { connect } from "react-redux";

import { auth } from "../../actions/user"
import isAuthenticated from "../../services/authService";

import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";

// import s from "./Invite.module.scss";

const Invite = (props) => {
    let location = useLocation();
    let history = useHistory();

    useEffect(() => {
        let fromURL = location.pathname + "?" + location.search 
        props.dispatch(auth(fromURL, () => {
            history.push("/");
        }));
    }, [])

    return (
        <div className="d-flex justify-content-center align-items-center" style={{ "minHeight": "100vh" }}>
            <LoadingIndicator />
        </div>
    )
}

Invite.propTypes = {
    dispatch: PropTypes.func.isRequired,
}

function mapStateToProps(store) {
    return {
        user: store.user.user,
    };
}

export default withRouter(connect(mapStateToProps)(Invite));
