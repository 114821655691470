import {
  OPEN_SIDEBAR,
  CLOSE_SIDEBAR,
  CHANGE_ACTIVE_SIDEBAR_ITEM,
  SELECT_PROJECT
} from "../actions/navigation.js";

import {
  LOGOUT_SUCCESS
} from "../actions/auth.js";

import {
  FETCH_PROJECT_RECORDS_ERROR
} from "../actions/user.js";

const initialState = {
  sidebarOpened: true,
  activeItem: JSON.parse(localStorage.getItem('staticSidebar')) ? window.location.pathname : null,
  selectedProjectId: localStorage.getItem('selectedProjectId') ?? window.location.pathname.split("/").pop() ?? null,
};

export default function runtime(state = initialState, action) {
  switch (action.type) {
    case LOGOUT_SUCCESS:
      localStorage.removeItem('selectedProjectId')

      return Object.assign({}, state, {
        selectedProjectId: null,
      });

    case OPEN_SIDEBAR:
      return Object.assign({}, state, {
        sidebarOpened: true,
      });
    case CLOSE_SIDEBAR:
      return Object.assign({}, state, {
        sidebarOpened: false,
      });
    case CHANGE_ACTIVE_SIDEBAR_ITEM:
      return {
        ...state,
        activeItem: action.activeItem,
      };
    case SELECT_PROJECT:
      console.log("SELECT_PROJECT", action);

      if (action.projectId == "" || action.projectId == "dashboard" || action.projectId == "projects") {
        return state;
      }

      localStorage.setItem('selectedProjectId', action.projectId)

      return {
        ...state,
        selectedProjectId: action.projectId
      }
    case FETCH_PROJECT_RECORDS_ERROR:
      localStorage.removeItem('selectedProjectId')
      return {
        ...state,
        selectedProjectId: null
      }
    default:
      return state;
  }
}
