import React from 'react';
import PropTypes from 'prop-types';

const SofiaLogo = (props) => {
  const {
    className = '',
    ...restProps
  } = props;

  return (
    <div className={className} {...restProps}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.86 27.22">
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
          <path fill="#000000" d="M19.12,14.92a9.63,9.63,0,0,1-6.18,2.23A8.38,8.38,0,0,1,4.3,8.64,8.51,8.51,0,0,1,13.11,0a9.65,9.65,0,0,1,6.06,2.2.75.75,0,0,1,.26.43.67.67,0,0,1-.17.4l-1,1.47a.56.56,0,0,1-.42.28.88.88,0,0,1-.43-.18,7.11,7.11,0,0,0-4.31-1.52A5.27,5.27,0,0,0,7.73,8.6a5.29,5.29,0,0,0,5.33,5.47,6.81,6.81,0,0,0,4.36-1.54.75.75,0,0,1,.45-.21.52.52,0,0,1,.38.26l1,1.49a.72.72,0,0,1,.14.35C19.43,14.59,19.33,14.73,19.12,14.92Z" />
            <path fill="#000000" d="M32.13.29c.4,0,.59.16.59.56V2.8c0,.4-.14.56-.59.56H25.59a.27.27,0,0,0-.31.31V7.15c0,.22.1.31.31.31h5.73c.45,0,.59.14.59.59V10c0,.4-.19.57-.59.57H25.59c-.21,0-.31.09-.31.31v5.44c0,.41-.19.57-.59.57H22.54c-.41,0-.57-.16-.57-.57V.85c0-.4.16-.56.57-.56Z" />
            <path fill="#000000" d="M18.41,27.22A61.09,61.09,0,0,1,0,24.17L1.34,20.4a57.85,57.85,0,0,0,17.07,2.82,54.07,54.07,0,0,0,17-2.81l1.41,3.74A57,57,0,0,1,18.41,27.22Z" />
          </g>
        </g>
      </svg>
    </div>
  );
}

SofiaLogo.propTypes = {
  className: PropTypes.string,
}

export default SofiaLogo;
