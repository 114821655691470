import {
    NOTIFICATIONS_LOADED,
    NOTIFICATION_UPDATED
} from "../actions/notifications.js"

const initialState = {
    notifications: [],
}

export default function projects(state = initialState, action) {
    switch (action.type) {
        case NOTIFICATIONS_LOADED:
            return Object.assign({}, state, {
                notifications: action.notifications,
            });

            case NOTIFICATION_UPDATED:
                return Object.assign({}, state, {
                    notifications: state.notifications.map(notification => {
                        if (notification._id === action.notification._id) {
                            return action.notification;
                        }
                        return notification;
                    }),
                });

        default:
            return state
    }
}
