import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes, { func } from "prop-types";
import { withLocalize, Translate, getActiveLanguage } from "react-localize-redux";
import { useHistory } from "react-router";
import Tag from "../../components/Tag/Tag";
import moment from "moment";

import {
    getProject,
    inviteToProject,
    resendInvite,
    cancelInvite,
    editProjectName,
    editProjectAccess,
    setProjectShortName,
    removeProjectAccess
} from "../../actions/projects.js";

import { auth, changeEmail, changePassword } from "../../actions/user";

import { Button, ButtonDropdown, DropdownToggle, DropdownItem, DropdownMenu, FormText, Input, InputGroup, InputGroupText, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';

import s from "./Profile.module.scss"

import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator.js";

const accessTypes = [
    "admin",
    "write",
    "read"
]

const Profile = (props) => {
    // const [projectId, setProjectId] = useState(null);
    const [project, setProject] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [openedButtonIndex, setOpenedButtonIndex] = useState(null);
    const [isAddingAccess, setIsAddingAccess] = useState(false);
    const [newUserDraft, setNewUserDraft] = useState({
        email: "",
        role: "write"
    });
    const [oldShortName, setOldShortName] = useState("");
    const [editShortNameError, setEditShortNameError] = useState(null);

    const [user, setUser] = useState(props.user);
    const [password, setPassword] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");

    const history = useHistory();

    useEffect(() => {
        props.dispatch(auth())
    }, [])

    useEffect(() => {
        if (props.user) {
            setUser(props.user);
        }
    }, [props.user])

    function setActiveLanguage(code) {
        console.log(code)
        props.setActiveLanguage(code);
        localStorage.setItem('currentLanguageCode', code);

        moment.locale(code);
    }

    function handleMailChanged(e) {
        setUser({
            ...user,
            email: e.target.value
        });
    }

    function handlePasswordChanged(e) {
        setPassword(e.target.value);
    }

    function changeEmailAction() {
        props.dispatch(changeEmail(user.email, password));
    }

    function oldPasswordChanged(e) {
        setOldPassword(e.target.value);
    }

    function newPasswordChanged(e) {
        setNewPassword(e.target.value);
    }

    function changePasswordAction() {
        props.dispatch(changePassword(oldPassword, newPassword));
    }

    const LanguageToggle = ({ languages, setActiveLanguage }) => {
        let currentLanguageCode = localStorage.getItem('currentLanguageCode')
        return (
            <div className={`d-flex flex-row flex-wrap ${s.gap10}`}>
                {
                    languages.map(lang => (
                        <Tag title={lang.name} isSelected={lang.code == currentLanguageCode} onClick={() => setActiveLanguage(lang.code)} />
                    ))
                }
            </div>
        )
    }

    return (
        <div className="w-100">
            {props.user == null ?
                <LoadingIndicator /> :
                (
                    <>
                        <h5 className={s.rootTitle}> <Translate id="profile.profile_title" /> </h5>

                        <div className={`d-flex flex-column ${s.gap10}`}>
                            <div className={`d-flex flex-column ${s.prjectContainer} ${s.gap10}`}>
                                <h5 className={s.rootTitle}> <Translate id="profile.profile_language" /> </h5>

                                <LanguageToggle languages={[
                                    { name: 'English', code: 'en' },
                                    { name: 'Русский', code: 'ru' }
                                ]} setActiveLanguage={(code) => setActiveLanguage(code)} />
                            </div>

                            <div className={`d-flex flex-column ${s.prjectContainer} ${s.gap10}`}>
                                <h5 className={s.rootTitle}> <Translate id="profile.subscription_title" /> </h5>
                                <div className={`d-flex flex-row flex-wrap ${s.gap10}`}>
                                    <h6 className={`${s.subscription_title}`}> <Translate id="profile.current_subscription" /> </h6> <Tag title="Basic" isSelected={true} /> <Tag title="Pro" isSelected={false} />
                                </div>

                                <p className={`${s.subscription_description}`}><Translate id="profile.subscriptions_description"></Translate></p>
                            </div>

                            <div className={`d-flex flex-column ${s.prjectContainer} ${s.gap10}`}>
                                <h5 className={s.rootTitle}> <Translate id="profile.change_email_title" /> </h5>

                                <label for="email"><Translate id="profile.new_email_title" /></label>
                                <Translate>
                                    {({ translate }) =>
                                        <Input
                                            id="email"
                                            className="input-transparent pl-3 w-100"
                                            value={user.email ?? ""}
                                            onChange={(event) => handleMailChanged(event)}
                                            type="text"
                                            required
                                            name="Email"
                                            placeholder={translate('profile.new_email_placeholder')}
                                        />

                                    }
                                </Translate>

                                <label for="password"><Translate id="profile.password_title" /></label>
                                <Translate>
                                    {({ translate }) =>
                                        <Input
                                            id="password"
                                            className="input-transparent pl-3 w-100"
                                            value={password ?? ""}
                                            onChange={(event) => handlePasswordChanged(event)}
                                            type="password"
                                            required
                                            name="Password"
                                            placeholder={translate('profile.password_placeholder')}
                                        />

                                    }
                                </Translate>

                                <div className="d-flex flex-row justify-content-between">
                                    <div></div>
                                    <Button color="primary" onClick={() => changeEmailAction()}><Translate id="settings.save" /></Button>
                                </div>
                            </div>

                            <div className={`d-flex flex-column ${s.prjectContainer} ${s.gap10}`}>
                                <h5 className={s.rootTitle}> <Translate id="profile.change_password_title" /> </h5>

                                <label for="old_password"><Translate id="profile.old_password_title" /></label>
                                <Translate>
                                    {({ translate }) =>
                                        <Input
                                            id="old_password"
                                            className="input-transparent pl-3 w-100"
                                            value={oldPassword ?? ""}
                                            onChange={(event) => oldPasswordChanged(event)}
                                            type="password"
                                            required
                                            name="Email"
                                            placeholder={translate('profile.old_password_placeholder')}
                                        />

                                    }
                                </Translate>

                                <label for="new_password"><Translate id="profile.new_password_title" /></label>
                                <Translate>
                                    {({ translate }) =>
                                        <Input
                                            id="new_password"
                                            className="input-transparent pl-3 w-100"
                                            value={newPassword ?? ""}
                                            onChange={(event) => newPasswordChanged(event)}
                                            type="password"
                                            required
                                            name="Password"
                                            placeholder={translate('profile.new_password_placeholder')}
                                        />

                                    }
                                </Translate>

                                <div className="d-flex flex-row justify-content-between">
                                    <div></div>
                                    <Button color="primary" onClick={() => changePasswordAction()}><Translate id="settings.save" /></Button>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
        </div>
    )
}

Profile.propTypes = {
    dispatch: PropTypes.func.isRequired,
}

function mapStateToProps(store) {
    return {
        user: store.user.user
    }
}

export default withLocalize(withRouter(connect(mapStateToProps)(Profile)))