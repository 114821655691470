import {
    PROJECT_LOADED,
    INVITE_SENT,
    INVITE_CANCELLED,
    PROJECT_DELETED,
    REPORTS_LOADED,
    REPORTS_LOADED_2,
    RECORD_HISTORY_LOADED
} from "../actions/projects.js"

import {
    SELECT_PROJECT
} from "../actions/navigation.js";

import {
    PROJECTS_UPDATE
} from "../actions/user.js"

const initialState = {
    projects: [],
    invites: {},
    reports: [],
    series: [],
    categories: [],
    history: []
}

export default function projects(state = initialState, action) {
    switch (action.type) {
        case PROJECTS_UPDATE:
            return Object.assign({}, state, {
                projects: action.projects,
            });

        case PROJECT_LOADED:
            let projects = state.projects;

            let projectIndex = projects.findIndex(project => project._id === action.project._id)
            if (projectIndex === -1) {
                projects.push(action.project);
            } else {
                projects[projectIndex] = action.project;
            }

            const invites = { ...state.invites, [action.project._id]: action.invites }

            return {
                ...state,
                projects,
                invites
            }

        case PROJECT_DELETED:
            return {
                ...state,
                projects: state.projects.filter(project => project._id !== action.projectId)
            }

        case INVITE_SENT:
            var projectInvites = state.invites[action.projectId] || []
            console.log(projectInvites, action.invite)
            var index = projectInvites.findIndex(invite => invite.email === action.invite.email)
            if (index === -1) {
                projectInvites.push(action.invite)
            } else {
                projectInvites[index] = action.invite
            }

            var newInvites = { ...state.invites, [action.projectId]: projectInvites }
            return {
                ...state,
                invites: newInvites
            }
        case INVITE_CANCELLED:
            var projectInvites = state.invites[action.projectId] || []
            var index = projectInvites.findIndex(invite => invite._id === action.inviteId)
            if (index !== -1) {
                projectInvites.splice(index, 1)
            }

            var newInvites = { ...state.invites, [action.projectId]: projectInvites }
            return {
                ...state,
                invites: newInvites
            }

        case REPORTS_LOADED:
            return {
                ...state,
                reports: action.reports
            }

        case REPORTS_LOADED_2:
            return {
                ...state,
                series: action.series,
                categories: action.categories
            }

        case RECORD_HISTORY_LOADED:
            return {
                ...state,
                history: action.history
            }

        default:
            return state
    }
}
