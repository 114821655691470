import React from 'react';
import PropTypes from 'prop-types';

import switchOn from '../../assets/switch-on.svg'
import switchOff from '../../assets/switch-off.svg'

import s from './Toggle.module.scss'

const Toggle = (props) => {
    const {
        title = null,
        isSelected = false,
        className = '',
        headerClass = '',
        children = [],
        options = {},
        ...restProps
    } = props;

    return (
        <div className={`${className} ${s.conainer}`} {...restProps} onClick={props.onClick}>
            <img className={s.icon} src={isSelected ? switchOn : switchOff}/>
            <span> {props.title} </span>
        </div>
    )
}

Toggle.propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    isSelected: PropTypes.bool,
    onClick: PropTypes.func,
    options: PropTypes.object,
}

export default Toggle;
