import React from "react";
import FooterIcon from "../Icons/FooterIcon";
import s from "./Backdrop.module.scss";

const Backdrop = (props) => {
  return (
    <div className={`${s.backdrop} ${props.show ? s.visible : s.hidden} `} onClick={props.toggleSidebar}>
    </div>
  )
}

export default Backdrop;