import axios from 'axios';

export const NOTIFICATIONS_LOADED = 'NOTIFICATIONS_LOADED';
export const NOTIFICATION_UPDATED = 'NOTIFICATION_UPDATED';

export function notificationsLoaded(notifications) {
    return {
        type: NOTIFICATIONS_LOADED,
        notifications
    };
}

export function notificationUpdated(notification) {
    return {
        type: NOTIFICATION_UPDATED,
        notification
    };
}

export function getNotifications() {
    return dispatch => {
        let token = localStorage.getItem('token');
        const url = `${process.env.REACT_APP_SERVER_IP}/api/notifications/get`;

        return axios.post(url, { token })
            .then(response => {
                if (response.data.success) {
                    dispatch(notificationsLoaded(response.data.notifications));
                }
            });
    }
}

export function markNotificationAsRead(notificationId) {
    return dispatch => {
        let token = localStorage.getItem('token');
        const url = `${process.env.REACT_APP_SERVER_IP}/api/notifications/read`;

        return axios.post(url, { token, notificationId })
            .then(response => {
                if (response.data.success) {
                    dispatch(notificationUpdated(response.data.notification));
                }
            });
    }
}
