import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter, Redirect, Link, useLocation, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
    Container,
    Row,
    Col,
    Button,
    FormGroup,
    FormText,
    Input,
} from "reactstrap";
import Widget from "../../components/Widget/Widget";
import Footer from "../../components/Footer/Footer";
import { loginUser, getToken, clearAuthErrors, restorePassword, restoreChangePassword } from "../../actions/auth";
import hasToken from "../../services/authService";

import Logo from "../../components/Icons/Logo.js"
import s from "./RestorePassword.module.scss";
import arrowWhite from "../../assets/arrow-white.svg";

import { Translate } from "react-localize-redux";
import icon from "../../assets/login-icon.png";

const RestorePassword = (props) => {

    const [state, setState] = useState({
        email: '',
        password: '',
    })

    const [error, setError] = useState(null)
    const [restoreCode, setRestoreCode] = useState(null)

    let location = useLocation()
    let history = useHistory()

    function doRestorePassword(e) {
        e.preventDefault();
        props.dispatch(restorePassword(state.email))
    }

    function doChangePassword(e) {
        e.preventDefault();
        props.dispatch(restoreChangePassword(restoreCode, state.password))
    }

    const doGetToken = () => {
        props.dispatch(getToken())
    }

    const changeCreds = (event) => {
        props.dispatch(clearAuthErrors());
        setState({ ...state, [event.target.name]: event.target.value })
    }

    const { from } = props.location.state || { from: { pathname: '/app' } };

    useEffect(() => {
        console.log('use effect')
        if (localStorage.getItem('token') == null) {
            console.log('no token')
            doGetToken()
        }
    }, [])

    useEffect(() => {
        if (location.search != undefined && location.search != "") {
            const query = new URLSearchParams(location.search)
            console.log(query.get('code'))

            if (query.get('code') != undefined) {
                setRestoreCode(query.get('code'))
            }
        }
    }, [location])

    if (hasToken(JSON.parse(localStorage.getItem('authenticated')))) {
        return (
            <Redirect to={from} />
        )
    }

    return (
        <div className="auth-page">
            <Container className="col-12">
                <div className={`${s.ladingHeader}`}>
                    <div className={`${s.ladingHeaderContent}`}>
                        <Link to="/" replace>
                            <Logo className={`${s.logo} ${s.pointer}`} />
                        </Link>
                    </div>
                </div>
                {restoreCode != null ?
                    <div className={`${s.pageContainer}`}>
                        <div className={`${s.contentContainer}`}>
                            <Widget className="widget-auth widget-p-lg">
                                <div className="d-flex align-items-center justify-content-between">
                                    <p className="auth-header mb-0"><Translate id="restore.restore_title" /><span><img className={s.titleIcon} src={icon} /></span></p>
                                </div>
                                <form onSubmit={(e) => { doChangePassword(e) }}>
                                    <FormGroup className="my-3">
                                        <FormText className="form-title"><Translate id="restore.new_password" /></FormText>
                                        <Translate>
                                            {({ translate }) =>
                                                <Input
                                                    id="password"
                                                    className="input-transparent pl-3"
                                                    value={state.password}
                                                    onChange={(event) => changeCreds(event)}
                                                    type="password"
                                                    required
                                                    name="password"
                                                    placeholder={translate('login.password_placeholder')}
                                                />
                                            }
                                        </Translate>
                                    </FormGroup>
                                    {(props.errorMessage) ? (
                                        <div className="alert alert-danger">
                                            {props.errorMessage}
                                        </div>) : null
                                    }
                                    <div className="bg-widget d-flex justify-content-start">
                                        <Button className={`${s.tryButton}`} type="submit" color="secondary-red">
                                            <Translate id="restore.change_password" />
                                            <img className={`${s.arrow}`} src={arrowWhite} alt="arrow" />
                                        </Button>
                                    </div>
                                </form>
                            </Widget>
                        </div>
                        <Footer />
                    </div>
                    : <div className={`${s.pageContainer}`}>
                        <div className={`${s.contentContainer}`}>
                            <Widget className="widget-auth widget-p-lg">
                                <div className="d-flex align-items-center justify-content-between">
                                    <p className="auth-header mb-0"><Translate id="restore.restore_title" /><span><img className={s.titleIcon} src={icon} /></span></p>
                                </div>
                                <form onSubmit={(event) => doRestorePassword(event)}>
                                    <FormGroup className="my-3">
                                        <FormText className="form-title"><Translate id="login.email" /></FormText>
                                        <Translate>
                                            {({ translate }) =>
                                                <Input
                                                    id="email"
                                                    className="input-transparent pl-3"
                                                    value={state.email}
                                                    onChange={(event) => changeCreds(event)}
                                                    type="email"
                                                    required
                                                    name="email"
                                                    placeholder={translate('login.email_placeholder')}
                                                />
                                            }
                                        </Translate>
                                    </FormGroup>
                                    {(props.errorMessage) ? (
                                        <div className="alert alert-danger">
                                            {props.errorMessage}
                                        </div>) : null
                                    }
                                    {(props.successfullyResetPassword == true) ? (
                                        <div className="alert alert-success">
                                            <Translate id="restore.email_sent" />
                                        </div>) : null
                                    }
                                    <div className="bg-widget d-flex justify-content-start">
                                        <Button className={`${s.tryButton}`} type="submit" color="secondary-red">
                                            <Translate id="restore.restore_button" />
                                            <img className={`${s.arrow}`} src={arrowWhite} alt="arrow" />
                                        </Button>
                                    </div>
                                </form>
                            </Widget>
                        </div>
                        <Footer />
                    </div>
                }
            </Container>
        </div>
    );
}

RestorePassword.propTypes = {
    dispatch: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
    return {
        isFetching: state.auth.isFetching,
        isAuthenticated: state.auth.isAuthenticated,
        errorMessage: state.auth.errorMessage,
        successfullyResetPassword: state.auth.successfullyResetPassword,
    };
}

export default withRouter(connect(mapStateToProps)(RestorePassword));
