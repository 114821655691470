import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Translate } from "react-localize-redux";
import { withRouter } from "react-router";
import { connect } from "react-redux";

import { markNotificationAsRead } from "../../actions/notifications";
import { acceptProjectInvite, declineProjectInvite } from "../../actions/projects";

import s from "./SidebarDrawer.module.scss";
import s2 from "./NotificationsSidebarWrapper.module.scss";
import SidebarDrawer from "./SidebarDrawer";
import { Button } from "reactstrap";

const NotificationsSidebarWrapper = (props) => {
    const [processingNotifications, setProcessingNotifications] = React.useState([]);
    
    function readNotification(notification) {
        if (notification.isRead) {
            return;
        }

        props.dispatch(markNotificationAsRead(notification._id));
    }

    function acceptInviteHandler(invite, notification) {
        setProcessingNotifications(processingNotifications.concat(notification._id));

        props.dispatch(acceptProjectInvite(invite._id, notification._id, () => {

        }));
    }

    function declineInviateHandler(invite, notification) {
        setProcessingNotifications(processingNotifications.concat(notification._id));

        props.dispatch(declineProjectInvite(invite, notification._id, () => {

        }));
    }

    function renderNotifications() {
        console.log(props.notifications);
        return (
            <>
                {props.notifications.map(notification => (
                    (notification.invite != null && (notification.invite.accepted == true || notification.invite.declined == true || notification.invite.cancelled)) ? (null) : (
                        <div className={`${s2.notificationContent} ${processingNotifications.includes(notification._id) ? s2.blocked : "" }`} key={notification._id} onClick={() => readNotification(notification)} onMouseEnter={() => readNotification(notification)}>
                            {notification.isRead ? <></> : <div className={s2.notReadBadge} />}

                            <div className={s2.notificationTitle}>
                                {notification.title}
                            </div>
                            <div className={s2.notificationText} dangerouslySetInnerHTML={{__html: notification.text}}>
                            </div>

                            {notification.invite && notification.invite.accepted !== true && notification.invite.cancelled !== true ? (
                                <div className={s2.inviteButtonsContainer}>
                                    <Button color="primary" className={`${s2.inviteButton} ${s2.inviteButtonAccept}`} onClick={() => acceptInviteHandler(notification.invite, notification)}>
                                        <Translate id="notifications.accept" />
                                    </Button>
                                    <Button className={`${s2.inviteButton}`} onClick={() => declineInviateHandler(notification.invite, notification)}>
                                        <Translate id="notifications.decline" />
                                    </Button>
                                </div>
                            ) : null}
                        </div>
                    )
                ))}
            </>
        )
    }

    return (
        <SidebarDrawer show={props.show} toggleSidebar={props.toggleSidebar}>
            <div className={s.drawerContent}>
                <div className='d-flex flex-row'>
                    <div className={`${s.drawerTitle}`}><Translate id="notifications.title" /></div>
                    <div className={`${s.closeButtonRight} ${s2.pointer}`} onClick={props.toggleSidebar}>
                        <i className={`eva eva-close-outline ${s.evaBigSize}`} />
                    </div>
                </div>
                <div className={`noScrollBar d-flex text-center  align-items-center flex-column ${s2.notificationsContainer}`}>
                    {/* <span><Translate id="notifications.not_available" /></span> */}
                    {renderNotifications()}
                </div>
            </div>
        </SidebarDrawer>
    )
}

NotificationsSidebarWrapper.propTypes = {
    dispatch: PropTypes.func.isRequired,
}

function mapStateToProps(store) {
    return {
        notifications: store.notifications.notifications,
    };
}

export default withRouter(connect(mapStateToProps)(NotificationsSidebarWrapper));