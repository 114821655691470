// -- React and related libs
import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router";
import { HashRouter, BrowserRouter } from "react-router-dom";
import { renderToStaticMarkup } from "react-dom/server";

import { Translate, withLocalize } from "react-localize-redux";
import ruTranslations from "./translations/ru.translations.json"
import enTranslations from "./translations/en.translations.json"

// import { auth } from "../actions/user";

// -- Redux
import { connect } from "react-redux";

// -- Custom Components
import LayoutComponent from "./components/Layout/Layout";
import ErrorPage from "./pages/error/ErrorPage";
import Login from "./pages/login/Login";
import Register from "./pages/register/Register";
import RestorePassword from "./pages/restorePassword/RestorePassword";
import Invite from "./pages/invite/Invite";
import Landing from "./pages/landing/Landing";
import CookieConsent from "./components/CookieConsent/CookieConsent";

// -- Redux Actions
import { auth, logoutUser } from "./actions/auth";

// -- Third Party Libs
import { ToastContainer } from "react-toastify";

// -- Services
import isAuthenticated from "./services/authService";

// -- Component Styles
import "./styles/app.scss";

const PrivateRoute = ({ dispatch, component, ...rest }) => {
  // console.log

  // const history = useHistory();
  // console.log(history.length);
  // console.log(history);
  // console.log(history.location.from)

  if (!isAuthenticated(JSON.parse(localStorage.getItem("authenticated")))) {
    return (<Redirect to="/" />)
  } else {
    return (
      <Route {...rest} render={props => (React.createElement(component, props))} />
    );
  }
};

const App = (props) => {

  const [isInitialized, setIsInitialized] = useState(false);
  const isCookieConsent = () => { return localStorage.getItem("isCookieConsent") }

  const [isCookieConsentOpened, setIsCookieConsentOpened] = useState(!isCookieConsent());

  const toggleCookieConsent = () => {
    localStorage.setItem("isCookieConsent", true)
    setIsCookieConsentOpened(!isCookieConsentOpened)
  }

  useEffect(() => {
    const languages = [
      { name: 'English', code: 'en' },
      { name: 'Русский', code: 'ru' }
    ]

    const defaultLanguage =
      getCurrentLanguage()

    props.initialize({
      languages,
      options: {
        renderToStaticMarkup,
        renderInnerHtml: true,
        defaultLanguage
      }
    })
  
    props.addTranslationForLanguage(enTranslations, "en")
    props.addTranslationForLanguage(ruTranslations, "ru")

    setIsInitialized(true);
  }, [])

  const ruCodes = ["ru", "be", "uk"]

  const getCurrentLanguage = () => {
    let code = localStorage.getItem("currentLanguageCode")

    if (code) return code

    let browserCode = getBrowserLanguage()
    
    code = ruCodes.includes(browserCode) ? "ru" : "en"

    localStorage.setItem("currentLanguageCode", code)

    return code
  }

  const getBrowserLanguage = () => {
    let code = (navigator.languages && navigator.languages[0]) ||
      navigator.language ||
      navigator.userLanguage

      if (code) {
        let splitCode = code.split("-")
        return splitCode[0]
      }

      return "en";
  }

  if (!isInitialized) return null;

  return (
    
      <div>
        <ToastContainer />
        <BrowserRouter>
          <Switch>
            <Route path="/" exact dispatch={props.dispatch} component={Landing} />
            <Route path="/app" exact render={() => <Redirect to="/app/dashboard" />} />
            <Route path="/invite" component={Invite} />
            <PrivateRoute path="/app" dispatch={props.dispatch} component={LayoutComponent} />
            <Route path="/login" exact component={Login} />
            <Route path="/restorePassword" component={RestorePassword} />
            <Route path="/error" exact component={ErrorPage} />
            <Route path="/register" exact component={Register} />
            <PrivateRoute path="/invite" dispatch={props.dispatch} render={() => <div />} />
            <Route component={ErrorPage} />
            <Route path='*' exact={true} render={() => <Redirect to="/error" />} />
          </Switch>
        </BrowserRouter>
        <CookieConsent
          isShowing={isCookieConsentOpened}
          hide={toggleCookieConsent}
        />
      </div>
  );
}

// const mapStateToProps = state => ({
//   isAuthenticated: state.auth.isAuthenticated,
// });

function mapStateToProps(store) {
  return {
    isAuthenticated: store.auth.isAuthenticated,
  };
}

export default withLocalize(connect(mapStateToProps)(App));
