import React from "react";
import PropTypes from "prop-types";
import { Translate } from "react-localize-redux";
import moment from "moment/min/moment-with-locales";

import Tag from "../Tag/Tag.js";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";

import s from "./RecordHistory.module.scss";

const RecordHistory = (props) => {
    if (localStorage.getItem('currentLanguageCode') == 'ru') {
        moment.locale('ru')
    }

    return (
        <div className={`${s.dimm} ${props.isOpened ? '' : 'd-none'}`}>
            <div className={`${s.contentContainer} noScrollBar`}>
                <div className={s.header}>
                    <div className={s.title}>
                        <Translate id="recordHistory.title" />
                    </div>

                    <i className={`eva eva-close-outline ${s.closeButton}`} onClick={() => props.onClose()} />
                </div>

                <div className={s.body}>
                    {props.history.length == 0 ? (
                        <div className={s.centerLoading}> <LoadingIndicator /> </div>
                    ) : null}

                    {
                        props.history.map((record, index) => (
                            <div className={s.record} key={index}>
                                <div className={s.recordHeader}>
                                    <div className={s.recordHeaderTitle}>
                                        <Translate id="recordHistory.changedBy" /> {record.user.email}
                                    </div>
                                    <div className={s.recordHeaderDate}>
                                        {moment(record.createdAt).format("D MMMM YYYY, HH:mm")}
                                    </div>
                                </div>


                                <div className={s.recordBody}>
                                    {record.newType != undefined && record.oldType != undefined ? (
                                        <div className={s.recordChangeConainer}>
                                            <div className={s.recordChangeTitle}>
                                                <Translate id="recordHistory.typeChanged" />
                                            </div>
                                            <div className={s.updateContainer}>
                                                <div className={s.recordBodyTypeOld}>
                                                    <Translate id="recordHistory.old" /> {
                                                        <Translate id={"records." + record.oldType} options={{
                                                            onMissingTranslation: ({ translationId, languageCode }) => {
                                                                return record.oldType
                                                            }
                                                        }} />
                                                    }
                                                </div>
                                                <div className={s.recordBodyTypeNew}>
                                                    <Translate id="recordHistory.new" /> {
                                                        <Translate id={"records." + record.newType} options={{
                                                            onMissingTranslation: ({ translationId, languageCode }) => {
                                                                return record.newType
                                                            }
                                                        }} />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}

                                    {record.newAmount != undefined || record.oldAmount != undefined ? (
                                        <div className={s.recordChangeConainer}>
                                            <div className={s.recordChangeTitle}>
                                                <Translate id="recordHistory.amountChanged" />
                                            </div>
                                            <div className={s.updateContainer}>
                                                <div className={s.recordBodyTypeOld}>
                                                    <Translate id="recordHistory.old" /> {record.oldAmount ?? 0}
                                                </div>
                                                <div className={s.recordBodyTypeNew}>
                                                    <Translate id="recordHistory.new" /> {record.newAmount}
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}

                                    {record.addedTags != undefined && record.addedTags.length > 0 ? (
                                        <div className={s.recordChangeConainer}>
                                            <div className={s.recordChangeTitle}>
                                                <Translate id="recordHistory.addedTags" />
                                            </div>
                                            <div className={s.tagsContainer}>
                                                {record.addedTags.map((tag, index) => (
                                                    <Tag key={index} tag={tag} title={tag.title} />
                                                ))}
                                            </div>
                                        </div>
                                    ) : null}

                                    {record.removedTags != undefined && record.removedTags.length > 0 ? (
                                        <div className={s.recordChangeConainer}>
                                            <div className={s.recordChangeTitle}>
                                                <Translate id="recordHistory.removedTags" />
                                            </div>
                                            <div className={s.tagsContainer}>
                                                {record.removedTags.map((tag, index) => (
                                                    <Tag key={index} tag={tag} title={tag.title} />
                                                ))}
                                            </div>
                                        </div>
                                    ) : null}

                                    {record.addImages != undefined && record.addImages.length > 0 ? (
                                        <div className={s.recordChangeConainer}>
                                            <div className={s.recordChangeTitle}>
                                                <Translate id="recordHistory.addedImages" />
                                            </div>
                                            <div className={s.tagsContainer}>
                                                {record.addImages.map((image, index) => (
                                                    <div className={s.imageContainer} key={index} title={image.name}>
                                                        <a href={image.url} target="_blank" className={`w-100 h-100 d-flex`}>
                                                            <img src={image.url} alt={image.title} />
                                                        </a>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ) : null}

                                    {record.removeImages != undefined && record.removeImages.length > 0 ? (
                                        <div className={s.recordChangeConainer}>
                                            <div className={s.recordChangeTitle}>
                                                <Translate id="recordHistory.removedImages" />
                                            </div>
                                            <div className={s.tagsContainer}>
                                                {record.removeImages.map((image, index) => (
                                                    <div className={s.imageContainer} key={index} title={image.name}>
                                                        <a href={image.url} target="_blank" className={`w-100 h-100 d-flex`}>
                                                            <img src={image.url} alt={image.title} />
                                                        </a>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ) : null}

                                    {record.addDocuments != undefined && record.addDocuments.length > 0 ? (
                                        <div className={s.recordChangeConainer}>
                                            <div className={s.recordChangeTitle}>
                                                <Translate id="recordHistory.addedDocuments" />
                                            </div>
                                            <div className={s.tagsContainer}>
                                                {record.addDocuments.map((file, index) => (
                                                    <div className={s.imageContainer} key={index}>
                                                        <a href={file.url} target="_blank" className={`${s.fileLink} w-100 h-100`}>
                                                            {file.name.split('.').slice(-1)[0].toUpperCase()}
                                                            <p>{file.name}</p>
                                                        </a>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ) : null}

                                    {record.removeDocuments != undefined && record.removeDocuments.length > 0 ? (
                                        <div className={s.recordChangeConainer}>
                                            <div className={s.recordChangeTitle}>
                                                <Translate id="recordHistory.removedDocuments" />
                                            </div>
                                            <div className={s.tagsContainer}>
                                                {record.removeDocuments.map((file, index) => (
                                                    <div className={s.imageContainer} key={index}>
                                                        <a href={file.url} target="_blank" className={`${s.fileLink} w-100 h-100`}>
                                                            {file.name.split('.').slice(-1)[0].toUpperCase()}
                                                            <p>{file.name}</p>
                                                        </a>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ) : null}

                                    {index == props.history.length - 1 ? (
                                        <div className={s.recordChangeConainer}>
                                            <div className={s.recordChangeTitle}>
                                                <Translate id="recordHistory.recordCreated" />
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

RecordHistory.propTypes = {
    history: PropTypes.object.isRequired,
    isOpened: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
}

export default RecordHistory;
