import axios from "axios";

export const UPDATE_USER = 'UPDATE_USER';
export const PROJECTS_UPDATE = 'PROJECTS_UPDATE'
export const SELECTED_PROJECT_CHAGNE = 'SELECTED_PROJECT_CHAGNE';
export const RECORDS_UPDATE = 'RECORDS_UPDATE';
export const RECORD_CREATED = 'RECORD_CREATED';
export const RECORD_UPDATED = 'RECORD_UPDATED';
export const POPUPLAR_TAGS_UPDATE = 'POPUPLAR_TAGS_UPDATE';
export const TAG_CREATED = 'TAG_CREATED';
export const FIND_RECORDS_UPDATED = 'FIND_RECORDS_UPDATED';
export const SELECT_PROJECT = "SELECT_PROJECT";
export const FETCH_PROJECT_RECORDS_ERROR = 'FETCH_PROJECT_RECORDS_ERROR';
export const SEARCH_PROJECT_RECORDS_ERROR = 'SEARCH_PROJECT_RECORDS_ERROR';

export function receiveUserUpdate(user) {
    return {
        type: UPDATE_USER,
        user,
    };
}

export function receiveProjectsUpdate(projects) {
    return {
        type: PROJECTS_UPDATE,
        projects,
    };
}

export function receiveProjectSelected(index) {
    return {
        type: SELECTED_PROJECT_CHAGNE,
        index
    }
}

export function receiveRecordsUpdate(records, hasMore) {
    return {
        type: RECORDS_UPDATE,
        hasMore,
        records,
    }
}

export function receiveRecordCreated(record) {
    return {
        type: RECORD_CREATED,
        record,
    }
}

export function receiveRecordUpdated(record) {
    return {
        type: RECORD_UPDATED,
        record,
    }
}

export function receivePopularTagsUpdate(tags) {
    return {
        type: POPUPLAR_TAGS_UPDATE,
        tags,
    }
}

export function receiveTagCreated(tag) {
    return {
        type: TAG_CREATED,
        tag,
    }
}

export function receiveFindRecordsUpdated(records) {
    return {
        type: FIND_RECORDS_UPDATED,
        records,
    }
}

export function receiveProjectIsEmpty() {
    return {
        type: SEARCH_PROJECT_RECORDS_ERROR,
        error: "no_records",
    }
}

export function receiveFetchProjectRecordsError(error) {
    return {
        type: FETCH_PROJECT_RECORDS_ERROR,
        error,
    }
}

export function auth(from, completion) {
    return (dispatch) => {
        let token = localStorage.getItem('token');
        const url = `${process.env.REACT_APP_SERVER_IP}/api/user/auth`;
        let params = {
            from,
            token
        }

        axios.post(url, params)
            .then((response) => {
                localStorage.setItem('token', response.data.session.token)

                if (response.data.projects) {
                    dispatch(receiveProjectsUpdate(response.data.projects));
                }

                if (response.data.session.user) {
                    dispatch(receiveUserUpdate(response.data.session.user));
                }

                if (completion !== null) {
                    completion(response.data.session.user !== null);  // true if user is logged in
                }

                console.log(response.data)
            })
            .catch((error) => {
                //   dispatch(loginError('Something was wrong. Try again'));
            });
    }
}

export function getRecordsForProject(projectId) {
    return (dispatch) => {
        let token = localStorage.getItem('token');
        const url = `${process.env.REACT_APP_SERVER_IP}/api/project/records/get?token=${token}&projectId=${projectId}`;

        axios.post(url, { token, projectId })
            .then((response) => {
                if (response.data.records) {
                    dispatch(receiveRecordsUpdate(response.data.records, response.data.hasMore));
                }

                if (response.data.success == false) {
                    dispatch(receiveFetchProjectRecordsError(response.data.error));
                }
            })
            .catch((error) => {
            });
    }
}

export function getRecordsForProjectByOffset(projectId, offset) {
    return (dispatch) => {
        let token = localStorage.getItem('token');
        const RECORDS_STACK_COUNT = 20;
        const url = `${process.env.REACT_APP_SERVER_IP}/api/project/records/get`;

        axios.post(url, { token, projectId, offset, limit: RECORDS_STACK_COUNT })
            .then((response) => {
                if (response.data.records) {
                    dispatch(receiveRecordsUpdate(response.data.records, response.data.hasMore));
                }

                if (response.data.success == false) {
                    console.log('ERROR', response.data)
                    dispatch(receiveFetchProjectRecordsError(response.data.error));
                }
            })
            .catch((error) => {
            });
    }
}

export function createRandomRecord(projectId) {
    return (dispatch) => {
        let token = localStorage.getItem('token');
        const url = `${process.env.REACT_APP_SERVER_IP}/api/project/records/createRandom`;

        axios.post(url, { token, projectId })
            .then((response) => {
                if (response.data.record) {
                    dispatch(receiveRecordCreated(response.data.record));
                }
            })
            .catch((error) => {
            });
    }
}

export function createRecord(projectId, record, completion) {
    return (dispatch) => {
        let token = localStorage.getItem('token');
        const url = `${process.env.REACT_APP_SERVER_IP}/api/project/records/create`;

        let tags = record.tags.map(tag => tag._id)
        let images = record.images.map(image => image._id)
        let documents = record.documents.map(document => document._id)
        let recordData = {
            title: record.title,
            comment: record.comment,
            amount: record.amount,
            type: record.type,
        }

        axios.post(url, { token, projectId, tags, images, documents, record: recordData })
            .then((response) => {
                if (response.data.record) {
                    dispatch(receiveRecordCreated(response.data.record));
                }

                if (completion != null) {
                    completion(response.data.error);
                }
            })
            .catch((error) => {
            });
    }
}

export function uploadFile(file, complete) {
    return () => {
        let token = localStorage.getItem('token');
        const url = `${process.env.REACT_APP_SERVER_IP}/api/files/upload`;
        let fromData = new FormData();
        fromData.append('file', file)
        fromData.append('token', token)

        axios.post(url, fromData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((response) => {
                if (!response.data.success) {
                    complete({ error: response.data.error })
                }

                console.log(response.data)
                complete({ file: response.data.data, type: response.data.type })
            })
            .catch((error) => {
                complete({ error })
            });
    }
}

export function editRecord(record) {
    return (dispatch) => {
        let token = localStorage.getItem('token');
        const url = `${process.env.REACT_APP_SERVER_IP}/api/project/record/edit`;

        let tags = record.tags.map(tag => tag._id)
        let images = record.images.map(image => image._id)
        let documents = record.documents.map(document => document._id)

        axios.post(url, {
            token, recordId: record._id, tags, images, documents, record: {
                title: record.title,
                comment: record.comment,
                amount: record.amount,
                type: record.type,
            }
        })
            .then((response) => {
                if (response.data.record) {
                    // dispatch(receiveRecordCreated(response.data.record));
                    dispatch(receiveRecordUpdated(response.data.record));
                }
            })
            .catch((error) => {
            });
    }
}

export function createTag(title, projectId, complete) {
    return (dispatch) => {
        let token = localStorage.getItem('token');
        const url = `${process.env.REACT_APP_SERVER_IP}/api/project/records/tags/create`;

        axios.post(url, { token, title, projectId })
            .then((response) => {
                console.log(response.data)
                if (response.data.tag) {
                    complete(response.data.tag)
                    dispatch(receiveTagCreated(response.data.tag))
                }
            })
            .catch((error) => {
            });
    }
}

export function getPopularTags(projectId, includeService) {
    return (dispatch) => {
        let token = localStorage.getItem('token');
        const url = `${process.env.REACT_APP_SERVER_IP}/api/project/getPopularTags`;

        axios.post(url, { token, projectId, includeService: includeService })
            .then((response) => {
                if (response.data.tags) {
                    dispatch(receivePopularTagsUpdate(response.data.tags));
                }
            })
            .catch((error) => {
            });
    }
}

export function selectProject(index) {
    return (dispatch) => {
        dispatch(receiveProjectSelected(index));
    }
}

export function findRecords(projectId, query, tags) {
    return (dispatch) => {
        let token = localStorage.getItem('token');
        const url = `${process.env.REACT_APP_SERVER_IP}/api/project/records/find`;
        dispatch(receiveFindRecordsUpdated([]));

        axios.post(url, { token, projectId, query, tags })
            .then((response) => {
                if (response.data.records) {
                    console.log("FIND RECORDS:", response.data.records)
                    dispatch(receiveFindRecordsUpdated(response.data.records));
                }

                if (response.data.isEmpty === true) {
                    dispatch(receiveProjectIsEmpty());
                }
            })
            .catch((error) => {
            });
    }
}

export function changeEmail(email, password, completion) {
    return (dispatch) => {
        let token = localStorage.getItem('token');
        const url = `${process.env.REACT_APP_SERVER_IP}/api/user/changeEmail`;

        axios.post(url, { token, email, password })
            .then((response) => {
                if (response.data.success) {
                    if (response.data.projects) {
                        dispatch(receiveProjectsUpdate(response.data.projects));
                    }

                    if (response.data.session.user) {
                        dispatch(receiveUserUpdate(response.data.session.user));
                    }

                    if (completion !== null) {
                        completion(response.data.session.user !== null);
                    }
                }
            })
            .catch((error) => {
            });
    }
}

export function changePassword(oldPassword, newPassword, completion) {
    return (dispatch) => {
        let token = localStorage.getItem('token');
        const url = `${process.env.REACT_APP_SERVER_IP}/api/user/changePassword`;

        axios.post(url, { token, password: oldPassword, newPassword })
            .then((response) => {
                if (response.data.success) {
                    if (response.data.projects) {
                        dispatch(receiveProjectsUpdate(response.data.projects));
                    }

                    if (response.data.session.user) {
                        dispatch(receiveUserUpdate(response.data.session.user));
                    }

                    if (completion !== null) {
                        completion(response.data.session.user !== null);
                    }
                }
            })
            .catch((error) => {
            });
    }
}
