import React, { useEffect, useState } from "react";
import { Translate } from "react-localize-redux";
import { Link } from 'react-router-dom';

import PropTypes from "prop-types";
import { withRouter, useLocation, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import SearchBar from "./components/SearchBar";
import { auth, findRecords, getPopularTags } from "../../actions/user";
import ProjectRecord from "../../components/ProjectRecord/ProjectRecord";
import s from './Search.module.scss';
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";

const SearchPage = (props) => {
  const [opnedIndex, setOpenedIndex] = useState(null);
  const [query, setQuery] = useState('');
  const [searchingTags, setSearchingTags] = useState([]);

  let location = useLocation()
  let history = useHistory()

  useEffect(() => {
    props.dispatch(auth())
  }, [])

  useEffect(() => {
    props.dispatch(getPopularTags(props.projectId, true))
  }, [props.projectId]);

  useEffect(() => {
    if (props.projects != undefined && props.projects.length > 0) {
      let tagsFromQuery = getTagsFromSearchQuery()

      props.dispatch(findRecords(projectId(), "", tagsFromQuery))
    }
  }, [props.popularTags]);

  function projectId() {
    return props.projectId;
  }

  function search(searchParams) {
    if (projectId() == null) return;

    let tags = searchParams.tags.map(x => x._id);

    setSearchingTags(tags);
    setQuery(searchParams.query);

    changeGetParams(tags ?? []);
    props.dispatch(findRecords(projectId(), searchParams.query, tags))
  }

  function changeGetParams(tags) {
    const params = new URLSearchParams({ "tags": tags.join(",") });
    history.replace({ pathname: location.pathname, search: params.toString() });
  }

  function getTagsFromSearchQuery() {
    if (location.search != undefined && location.search != "") {
      const query = new URLSearchParams(location.search)

      console.log(location.search)

      const tags = query.get('tags')
      if (tags != undefined && tags.trim() != "") {
        let tagsIds = tags.split(",")
        return props.popularTags.filter(x => tagsIds.includes(x._id))
      }
    }

    return [];
  }

  function isEditor() {
    if (props.projects != undefined && props.projects.length > 0) {
      let project = props.projects.find(p => p._id == props.projectId || p.shortName == props.projectId)

      if (project == undefined) {
        return false
      }

      if (props.user._id == undefined) {
        return false
      }

      let projectAccess = project.access.find(x => x.user._id.toString() == props.user._id.toString())

      if (projectAccess != null && projectAccess != undefined && (projectAccess.access == "admin" || projectAccess.access == "write")) {
        return true
      }
    }

    return false
  }

  function renderSearchBar() {
    return (<SearchBar popularTags={props.popularTags} selectedTags={getTagsFromSearchQuery()} dispatch={props.dispatch} onChanged={(searchParams) => { search(searchParams) }} />)
  }

  if (props.popularTags == undefined) return (null);

  if (props.records.length == 0) {
    return (<div>
      {renderSearchBar()}
      {(query === '' && searchingTags.length == 0) ?
        <div className={`d-flex text-center justify-content-center align-items-center flex-column ${s.tutorialText}`}>
          <Translate id="search.emptySearch" />
        </div> :
        <div className={`d-flex text-center justify-content-center align-items-center flex-column ${s.tutorialText}`}>
          {props.isProjectEmpty ? (<span><Translate id="search.no_records" /></span>) : (<span><Translate id="search.no_results" /></span>)}
        </div>
      }
    </div>)
  }

  return (
    <div>
      {renderSearchBar()}

      {

        props.records.map((record, index) => (
          <>
            {
              // renderDate(Date.parse(record.updatedAt), index > 0 ? Date.parse(props.records[index - 1].updatedAt) : null)
            }
            <ProjectRecord key={record._id} record={record} projectId={projectId()} dispatch={props.dispatch} popularTags={props.popularTags} isOpened={opnedIndex === index} onOpen={(isOpened) => {
              if (isOpened) {
                setOpenedIndex(index)
              } else if (opnedIndex === index) {
                setOpenedIndex(null)
              }
            }} canEdit={isEditor()} />
          </>
        ))
      }
    </div>
  )
}

function mapStateToProps(store) {
  return {
    user: store.user.user,
    projects: store.user.projects,
    records: store.user.records,
    popularTags: store.user.popularTags,
    projectId: store.navigation.selectedProjectId,
    isProjectEmpty: store.user.isProjectEmpty
  };
}

export default withRouter(connect(mapStateToProps)(SearchPage));