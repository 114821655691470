import React from 'react';
import ReactDOM from 'react-dom';
import { Translate } from "react-localize-redux";

import s from "./CookieConsent.module.scss";

const CookieConsent = ({ isShowing, hide }) => 
{
    return (
    isShowing ? ReactDOM.createPortal(
    <React.Fragment>
        {/* <div className={s.modalOverlay}>
        </div> */}

        <div className={s.modalWrapper}>
            <div className={s.modal}>
                <div className={s.modalHeader}>
                    {/* <button type="button" className={s.modalCloseButton} data-dismiss="modal" aria-label="Close" onClick={hide}>
                        <i class="eva eva-close-outline"></i>
                    </button> */}
                </div>
                <div className={`d-flex flex-row justify-content-between align-items-center ${s.gap10}`}>
                    <div>
                        <Translate id="common.cookie_consent_text" />
                    </div>
                    <button className={`${s.modalCloseButton} btn btn-primary`} onClick={hide}><Translate id="common.cookie_consent_accept" /></button>
                </div>
            </div>
        </div>
    </React.Fragment>, document.body
    ) : (null))
}

export default CookieConsent;