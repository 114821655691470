import axios from 'axios';

import { UPDATE_USER } from './user';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const CLEAR_AUTH_ERRORS = 'CLEAR_AUTH_ERRORS';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';

export function receiveLogin() {
  return {
    type: LOGIN_SUCCESS
  };
}

function loginError(payload) {
  return {
    type: LOGIN_FAILURE,
    payload,
  };
}

function requestLogout() {
  return {
    type: LOGOUT_REQUEST,
  };
}

export function receiveLogout() {
  return {
    type: LOGOUT_SUCCESS,
  };
}

export function receiveUserUpdate(user) {
  return {
    type: UPDATE_USER,
    user,
  };
}

export function receiveSuccessResetPassword() {
  return {
    type: RESET_PASSWORD_SUCCESS,
  };
}

// logs the user out
export function logoutUser() {
  return (dispatch) => {
    dispatch(requestLogout());

    let url = `${process.env.REACT_APP_SERVER_IP}/api/user/logout`
    axios.post(url)
      .then((response) => {
        localStorage.setItem('token', response.data.session.token);
        localStorage.removeItem('user');
        localStorage.setItem('authenticated', false)

        dispatch(receiveLogout());
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function loginUser(creds) {
  return (dispatch) => {
    console.log('env', process.env)
    const url = `${process.env.REACT_APP_SERVER_IP}/api/user/login`;
    console.log(url);

    axios.post(url, {
      email: creds.email,
      password: creds.password,
      token: localStorage.getItem('token'),
    }).then((response) => {
      console.log(response.data);

      if (response.data.success) {
        localStorage.setItem('user', JSON.stringify(response.data.session.user))
        localStorage.setItem('token', response.data.session.token)
        localStorage.setItem('authenticated', true)

        dispatch(receiveUserUpdate(response.data.session.user));
        dispatch(receiveLogin());
      } else {
        dispatch(loginError(response.data.error));
      }
    }).catch((error) => {
      dispatch(loginError(error));
    });
  }
}

export function restorePassword(email) {
  return (dispatch) => {
    const url = `${process.env.REACT_APP_SERVER_IP}/api/user/restorePassword`;
    console.log(url);

    axios.post(url, {
      email: email,
      token: localStorage.getItem('token'),
      lang: localStorage.getItem('currentLanguageCode')
    }).then((response) => {
      if (response.data.success) {
        if (response.data.session != null) {
          localStorage.setItem('token', response.data.session.token)
        }

        dispatch(receiveSuccessResetPassword());
        dispatch(clearAuthErrors());
      } else {
        dispatch(loginError(response.data.error));
      }
    }).catch((error) => {
      dispatch(loginError(error));
    });
  }
}

export function restoreChangePassword(code, newPassword) {
  return (dispatch) => {
    const url = `${process.env.REACT_APP_SERVER_IP}/api/user/restoreChangePassword`;
    console.log(url);

    axios.post(url, {
      newPassword: newPassword,
      code: code,
      token: localStorage.getItem('token'),
    }).then((response) => {
      if (response.data.success) {
        if (response.data.session != null) {
          localStorage.setItem('user', JSON.stringify(response.data.session.user))
          localStorage.setItem('token', response.data.session.token)
          localStorage.setItem('authenticated', true)

          dispatch(receiveUserUpdate(response.data.session.user));
          dispatch(receiveLogin());
        }

        dispatch(clearAuthErrors());
      } else {
        dispatch(loginError(response.data.error));
      }
    }).catch((error) => {
      dispatch(loginError(error));
    });
  }
}


export function getToken() {
  return (dispatch) => {
    console.log('env', process.env)
    
    const url = `${process.env.REACT_APP_SERVER_IP}/api/user/auth?token=${localStorage.getItem('token')}`;

    axios.get(url)
      .then((response) => {
        localStorage.setItem('token', response.data.session.token)
      })
      .catch((error) => {
        dispatch(loginError('Something was wrong. Try again'));
      });
  }
}

export function clearAuthErrors() {
  return {
    type: CLEAR_AUTH_ERRORS,
    payload: '',
  };
}
